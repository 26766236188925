import sortBy from 'lodash/sortBy';
import { PatientDTO, VisitDTO } from '~/dtos';

export const hasAnyVisits = (patient: PatientDTO): boolean => {
  return patient.visits.length > 0;
};

export const findLatestVisit = (patient: PatientDTO): VisitDTO | undefined => {
  if (!hasAnyVisits(patient)) {
    return undefined;
  }

  const active = findActiveVisit(patient);
  if (active) {
    return active;
  }

  const sortedVisits = sortBy(patient.visits, 'endedAt');

  return sortedVisits.reverse()[0];
};

export const findActiveVisit = (patient: PatientDTO): VisitDTO | undefined => {
  if (!hasAnyVisits(patient)) {
    return undefined;
  }

  return patient.visits.find((v) => v.endedAt === null);
};

export const findVisitById = (
  patient: PatientDTO,
  id: string,
): VisitDTO | undefined => {
  return patient.visits.find((v) => v.id === id);
};

export const findActiveOrRecentVisit = (
  patient?: PatientDTO,
): VisitDTO | undefined => {
  if (!patient || !hasAnyVisits(patient)) {
    return undefined;
  }

  const active = findActiveVisit(patient);

  if (active) {
    return active;
  }

  const sorted = sortBy(patient.visits, 'createdAt');
  return sorted[0];
};
