import React from 'react';
import { EpicrisisDTO, Status, VisitDTO } from '~/dtos';
import dayjs from 'dayjs';
import { Button, Collapse, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface Props {
  epicrisises: EpicrisisDTO[];
  editUrl: (res: EpicrisisDTO) => void;
  visit: VisitDTO;
}

export const EpicrisesList = ({ epicrisises, editUrl, visit }: Props) => {
  return (
    <Collapse accordion>
      {epicrisises.map((epicrisis) => (
        <Collapse.Panel
          extra={
            <Button
              size='small'
              onClick={() => editUrl(epicrisis)}
              disabled={visit.status !== Status.ACTIVE}
            >
              <EditOutlined />
            </Button>
          }
          key={epicrisis.id}
          header={`Epikryza ${epicrisis.type} – dodane przez ${
            epicrisis.addedBy.name
          } ${dayjs(epicrisis.createdAt).format('DD/MM/YYYY')} (dodano: ${dayjs(
            epicrisis.createdAt,
          ).fromNow()})`}
        >
          <Typography.Title level={5}>
            Opis pacjenta podczas pobytu:
          </Typography.Title>
          <Typography.Paragraph>{epicrisis.description}</Typography.Paragraph>

          <Typography.Title level={5}>Zastosowane leczenie:</Typography.Title>
          <Typography.Paragraph>{epicrisis.treatment}</Typography.Paragraph>

          <Typography.Title level={5}>
            Stan pacjenta przy wypisie:
          </Typography.Title>
          <Typography.Paragraph>{epicrisis.state}</Typography.Paragraph>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
