import RequestService from './Request';

import {
  FileDownloadDTO,
  UploadDocumentDTO,
  UploadDocumentResponseDTO,
} from '~/dtos';

class _DocumentService {
  getExaminationDocument(documentId: string): Promise<FileDownloadDTO> {
    return RequestService.client
      .get<FileDownloadDTO>(`/documents/download/examination/${documentId}`)
      .then((response) => response.data);
  }

  getVisitDocument(documentId: string): Promise<FileDownloadDTO> {
    return RequestService.client
      .get<FileDownloadDTO>(`/documents/download/visit/${documentId}`)
      .then((response) => response.data);
  }

  getUploadUrl(data: UploadDocumentDTO): Promise<UploadDocumentResponseDTO> {
    return RequestService.client
      .post<UploadDocumentResponseDTO>('/documents/upload', data)
      .then((response) => response.data);
  }
}

const DocumentService = new _DocumentService();

export { DocumentService, _DocumentService };
