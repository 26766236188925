import React from 'react';
import { UserDTO } from '~/dtos';
import { Avatar, Space, Tag } from 'antd';

interface Props {
  doctor?: UserDTO | null;
}

export const DoctorName = ({ doctor }: Props) => {
  if (!doctor) {
    return <Tag color='blue'>Brak</Tag>;
  }

  return (
    <Space>
      <div>{doctor.name}</div>
      <Avatar size={24} src={doctor?.avatarUrl} />
    </Space>
  );
};
