import React, { useState } from 'react';
import { Button, Card, List, Popconfirm, Space } from 'antd';
import { PatientDTO, Status, VisitDTO } from '~/dtos';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { DocumentModal } from '~/views/dashboard/patient/views/files/DocumentModal';
import { useDeleteVisitFile, useVisitFiles } from '~/queries/useVisit';
import dateUtils from '~/utils/date';
import { DownloadButton } from '~/components/DownloadButton';

export interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const VisitFilesView = ({ patient, visit }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { isLoading, data } = useVisitFiles(visit.id);
  const { mutate, isLoading: isDeleting } = useDeleteVisitFile(visit.id);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: 16 }}>
      <Card
        loading={isLoading}
        title={
          <div>
            <b>Dokumenty pobytu</b> (dokumenty nie są dodawane do wypisu)
          </div>
        }
        type='inner'
        extra={
          <Button type='primary' onClick={() => showModal()}>
            Dodaj nowy dokument
          </Button>
        }
      >
        <List>
          {data?.map((file) => (
            <List.Item
              key={file.id}
              extra={
                <Space>
                  <DownloadButton
                    documentId={file.id}
                    version='visit-file'
                    size='small'
                  >
                    <DownloadOutlined />
                  </DownloadButton>
                  <Popconfirm
                    placement='topRight'
                    title='Czy na pewno chcesz usunąć ten dokument?'
                    onConfirm={() => mutate({ fileId: file.id })}
                    okText='Tak'
                    cancelText='Nie'
                  >
                    <Button
                      size='small'
                      loading={isDeleting}
                      disabled={visit.status !== Status.ACTIVE}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Space>
              }
            >
              <div>
                <b>{file.name}</b>{' '}
                {file.description ? `– ${file.description} ` : ' '}
                <i>
                  ({file.uploadedBy.name || file.uploadedBy.email} –{' '}
                  {dateUtils.fromNow(file.createdAt)})
                </i>
              </div>
            </List.Item>
          ))}
        </List>
        {!isLoading && data?.length === 0 && 'Brak dokumentów'}
      </Card>
      <DocumentModal
        visible={isModalVisible}
        onClose={closeModal}
        visit={visit}
      />
    </div>
  );
};
