import { Button, Card, DatePicker, Form } from 'antd';
import * as yup from 'yup';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DepartmentSelect } from '~/components/DepartmentSelect';
import { useHistory, useLocation } from 'react-router-dom';
import { VisitDTO } from '~/dtos';
import { GoBackButton } from '~/components/GoBackButton';
import moment from 'moment';
import { useUpdateVisit } from '~/queries/useVisit';

const schema = yup.object().shape({
  department: yup.string().required('Placówka jest wymagana'),
  startedAt: yup.string().required('Data przyjścia do placówki jest wymagana'),
});

export const VisitForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation<{ visit?: VisitDTO }>();
  const { handleSubmit, control, formState, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (location.state?.visit) {
      const { visit } = location.state;
      setValue('department', visit.department);
      setValue('startedAt', moment(visit.startedAt));
    }
  }, [setValue, location.state]);

  const { mutate: updateStay, isLoading } = useUpdateVisit(
    location.state?.visit?.id || '',
    'unknown',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data) => {
      updateStay(data);
    },
    [updateStay],
  );

  const Row: React.FC = (props) => (
    <div
      style={{
        alignItems: 'center',
        gap: 8,
        marginBottom: 8,
        width: '100%',
        display: 'inline-flex',
      }}
    >
      {props.children}
    </div>
  );

  return (
    <Card title={<GoBackButton title='Formularz edytowania pobytu' />}>
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Row>
          <Form.Item
            label='Placówka'
            required
            style={{ width: '100%' }}
            validateStatus={
              formState.errors.department ? 'error' : 'validating'
            }
            help={
              formState.errors.department
                ? formState.errors.department.message
                : undefined
            }
          >
            <Controller
              name='department'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <DepartmentSelect
                  onSelect={field.onChange}
                  selectedDepartment={field.value}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label='Data przyjścia do placówki'
            required
            style={{ width: '100%' }}
            validateStatus={formState.errors.startedAt ? 'error' : 'validating'}
            help={
              formState.errors.startedAt
                ? formState.errors.startedAt.message
                : undefined
            }
          >
            <Controller
              name='startedAt'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <DatePicker
                  placeholder='Wybierz date'
                  onChange={field.onChange}
                  value={field.value}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Form.Item>
        </Row>
        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          Edytuj pobyt
        </Button>
      </Form>
    </Card>
  );
};
