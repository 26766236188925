import * as queryString from 'query-string';
import { Action, action, Thunk, thunk } from 'easy-peasy';
import { ErrorPageParams } from '~/components/GenericErrorPage';
import { auth0ErrorToErrorPageParams } from '~/utils/error-parser';
import { ROUTES } from '~/constants';
import { Auth0Error } from 'auth0-js';
import { StoreInjections, StoreModel } from '~/global-state/types';

const handleAuthenticationError = (
  error: AuthError,
  onUnauthorizedError: (url: string) => void,
  onGenericError: (url: string) => void,
) => {
  const params: ErrorPageParams =
    typeof error === 'string' ? { error } : auth0ErrorToErrorPageParams(error);

  const url = `${ROUTES.ERROR}?${queryString.stringify({
    ...params,
    showHomeButton: true,
  })}`;

  // We do want to logout the user in case of unauthorized error because
  // auth0 caches recently logged in user, so we don't want to make a
  // "wrong circle" that user will constantly see the error. (really
  // bad case would be when by accident vet would use wrong email
  // address. Without this they won't be able to fix it and will
  // report bugs.)
  if (typeof error !== 'string' && error.error === 'unauthorized') {
    return onUnauthorizedError(url);
  } else {
    onGenericError(url);
  }
};

export interface AuthModel {
  isAuthenticated: boolean;
  setAsAuthenticated: Action<AuthModel, void>;
  authenticate: Thunk<AuthModel, void, StoreInjections, StoreModel>;
  logout: Thunk<AuthModel, void, StoreInjections, StoreModel>;
}

export type AuthError = string | Auth0Error;

const authState: AuthModel = {
  isAuthenticated: false,

  setAsAuthenticated: action<AuthModel, void>((state) => {
    state.isAuthenticated = true;
  }),

  authenticate: thunk(async (actions, payload, helpers) => {
    const { authService, history } = helpers.injections;

    try {
      await authService.handleAuthentication();
      actions.setAsAuthenticated();
    } catch (err) {
      handleAuthenticationError(err, authService.logout, history.push);
    }
  }),

  logout: thunk(async (actions, payload, helpers) => {
    const { authService } = helpers.injections;
    authService.logout();
  }),
};

export default authState;
