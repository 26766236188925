import React from 'react';
import Helmet from 'react-helmet';

import { HOTJAR_SITE_ID, META, PROD_ENV } from '~/constants';

const Head: React.FC = () => (
  <Helmet
    defaultTitle={META.title}
    titleTemplate={META.titleTemplate}
    titleAttributes={{ itemprop: 'name', lang: 'pl' }}
  >
    {HOTJAR_SITE_ID && PROD_ENV && (
      <script>
        {`(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${HOTJAR_SITE_ID},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </script>
    )}
  </Helmet>
);

export default Head;
