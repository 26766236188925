import qs from 'query-string';
import RequestService from './Request';

import {
  CreatePatientDTO,
  UpdatePatientDTO,
  PatientDTO,
  PatientQuery,
} from '~/dtos';

class _PatientService {
  getPatients(query?: PatientQuery): Promise<PatientDTO[]> {
    const url = `/patients?${qs.stringify(
      { ...query },
      { skipNull: true, skipEmptyString: true },
    )}`;

    return RequestService.client
      .get<PatientDTO[]>(url)
      .then((response) => response.data);
  }

  getPatient(id: string): Promise<PatientDTO> {
    return RequestService.client
      .get<PatientDTO>(`/patients/${id}`)
      .then((response) => response.data);
  }

  createPatient(data: CreatePatientDTO): Promise<PatientDTO> {
    return RequestService.client
      .post<PatientDTO>('/patients', data)
      .then((response) => response.data);
  }

  removePatient(id: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/patients/${id}`)
      .then(() => Promise.resolve());
  }

  updatePatient(id: string, data: UpdatePatientDTO): Promise<PatientDTO> {
    return RequestService.client
      .patch<PatientDTO>(`/patients/${id}`, data)
      .then((response) => response.data);
  }
}

const PatientService = new _PatientService();

export { PatientService, _PatientService };
