import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import { useCreatePsychoObservations } from '~/queries/useVisit';
import {
  CreatePsychoObservationDTO,
  PatientDTO,
  PsychoObservationDTO,
  VisitDTO,
} from '~/dtos';
import { useUpdatePsychoObservation } from '~/queries/usePsychoObservation';
import TextArea from 'antd/lib/input/TextArea';
import { GoBackButton } from '~/components/GoBackButton';

const schema = yup.object().shape({
  content: yup.string().required('To pole jest wymagane.'),
});

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const SpecialistObservationForm = ({ visit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation<{ psychoObservation?: PsychoObservationDTO }>();
  const isEditing = Boolean(location.state?.psychoObservation);

  const formUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.SPECIALIST_OBSERVATIONS,
    patient.id,
    visit.id,
  );

  const { handleSubmit, control, formState, setValue } =
    useForm<CreatePsychoObservationDTO>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (location.state?.psychoObservation) {
      const { psychoObservation } = location.state;
      setValue('content', psychoObservation.content);
    }
  }, [location.state, setValue]);

  const { mutate: createPsychoObservation, isLoading } =
    useCreatePsychoObservations(visit.id, () => {
      history.push(formUrl);
    });

  const { mutate: updatePsychoObservation } = useUpdatePsychoObservation(
    location.state?.psychoObservation?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data) => {
      if (isEditing) {
        updatePsychoObservation(data);
      } else {
        createPsychoObservation(data);
      }
    },
    [updatePsychoObservation, createPsychoObservation, isEditing],
  );

  const { errors } = formState;

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawania nowej'
          } obserwacji specjalistycznej`}
        />
      }
      style={{ marginTop: 16 }}
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Form.Item
          label='Opis'
          required
          validateStatus={formState.errors?.content ? 'error' : 'validating'}
          help={errors?.content?.message}
        >
          <Controller
            name='content'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea autoSize {...field} value={field.value || ''} />
            )}
          />
        </Form.Item>
        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          {isEditing ? 'Edytuj' : 'Dodaj'} obserwacje
        </Button>
      </Form>
    </Card>
  );
};
