import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pl';
dayjs.locale('pl');
dayjs.extend(relativeTime);

const dateUtils = {
  format: (d: dayjs.ConfigType, pattern: string = 'DD/MM/YYYY, hh:mm') =>
    dayjs(d).format(pattern),
  fromNow: (d: dayjs.ConfigType) => dayjs(d).fromNow(),
  isPastDate: (d: dayjs.ConfigType) => dayjs().isAfter(d),
  isAfter: (d1: dayjs.ConfigType, d2: dayjs.ConfigType) =>
    dayjs(d1).isAfter(d2),
  diff: (d1: dayjs.ConfigType, d2: dayjs.ConfigType) => dayjs(d1).diff(d2),
};

export default dateUtils;
