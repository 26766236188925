import { DatePicker, Form, Input, Modal } from 'antd';
import React, { useCallback } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCancelVisit } from '~/queries/useVisit';
import { VisitDTO } from '~/dtos';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface Props {
  visible: boolean;
  onClose: () => void;
  visit: VisitDTO;
}

const schema = yup.object().shape({
  endDate: yup.string().required('Data zakończenia wizyty jest wymagana'),
  endReason: yup
    .string()
    .min(10, 'Powód musi mieć minimum 10 znaków')
    .required('Powód zakończenia wizyty jest wymagany'),
});

const { confirm } = Modal;

function showDeleteConfirm(reason: string, onOkFn: () => void) {
  confirm({
    title: `Czy na pewno anulować pobyt?`,
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        <p>Pobyt zostanie anulowana bezpowrotnie.</p>
        <b>Powód:</b>
        <br />
        {reason}
      </div>
    ),
    okText: 'Tak, anuluj',
    okType: 'danger',
    cancelText: 'Nie chcę anulować wizyty',
    width: 500,
    onOk: onOkFn,
    style: { top: 170 },
  });
}

moment.locale('pl');
export const CancelVisitModal = ({ visible, onClose, visit }: Props) => {
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: cancelVisit, isLoading } = useCancelVisit(visit.id, () => {
    onClose();
  });

  const onSubmit = useCallback(
    (data) => {
      showDeleteConfirm(data.endReason, () => {
        cancelVisit({
          endReason: data.endReason,
          endedAt: moment(data.endDate).toISOString(),
        });
      });
    },
    [cancelVisit],
  );

  const { endDate, endReason } = formState.errors;

  return (
    <Modal
      confirmLoading={isLoading}
      title='Anuluj pobyt pacjenta'
      visible={visible}
      onOk={handleSubmit(onSubmit)}
      onCancel={() => onClose()}
      okText='Anuluj pobyt'
      cancelText='Wróć'
    >
      <Form layout='vertical'>
        <Form.Item
          label='Data'
          required
          validateStatus={endDate ? 'error' : 'validating'}
          help={endDate ? endDate.message : undefined}
        >
          <Controller
            name='endDate'
            control={control}
            defaultValue={moment()}
            render={({ field }) => (
              <DatePicker
                allowClear={false}
                placeholder='Wybierz date'
                onChange={field.onChange}
                value={field.value}
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label='Powód'
          required
          validateStatus={endReason ? 'error' : 'validating'}
          help={endReason ? endReason.message : undefined}
        >
          <Controller
            name='endReason'
            control={control}
            render={({ field }) => (
              <Input.TextArea
                onChange={field.onChange}
                value={field.value}
                placeholder='Wpisz powód anulacji pobutu'
              />
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
