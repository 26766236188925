import { AxiosError } from 'axios';
import { Auth0Error } from 'auth0-js';

import { DEFAULT_ERROR_MESSAGE } from '~/constants';
import { statusNames } from '~/utils/status-names';
import { ErrorPageParams } from '~/components/GenericErrorPage';

export interface IError<DTO = Record<string, unknown>> {
  reason: string;
  status?: number;
  validationErrors?: Partial<DTO>;
}

const getErrorMessage = (error: AxiosError): string => {
  if (error.response) {
    const { data, status } = error.response;

    if (data.message) {
      return data.message;
    }

    if (status >= 500) {
      return DEFAULT_ERROR_MESSAGE;
    }

    if (status === 400 && data.errors) {
      return 'There were some errors with your submission';
    }

    // We cannot use "error.response.status.statusText" here
    // because some servers (like our dev/sth/prod)
    // don't return this by default, so that we have to
    // manually convert it.
    return statusNames(error.response.status);
  }

  return error.message;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAxiosError = (error: any): error is AxiosError => {
  return error.response && error.config;
};

export const parseError = (error: AxiosError | Error): IError => {
  if (isAxiosError(error)) {
    return {
      reason: getErrorMessage(error),
      status: error.response ? error.response.status : undefined,
      validationErrors: error.response ? error.response.data.errors : undefined,
    };
  }

  return {
    reason: error.message || DEFAULT_ERROR_MESSAGE,
  };
};

export const auth0ErrorToErrorPageParams = (
  error: Auth0Error,
): ErrorPageParams => ({
  error: error.error,
  errorDescription: error.errorDescription,
});
