import { useMutation, useQuery } from 'react-query';
import { ActivityDTO, CreateUserDTO, UserDTO, UserStatsDTO } from '~/dtos';
import { AxiosError } from 'axios';
import { UserService } from '~/services/User';
import { queryClient } from '~/constants';
import { message } from 'antd';
import { handleError } from '~/queries/common';

export const USERS_KEY = 'users';

export const useUsers = () =>
  useQuery<UserDTO[]>(USERS_KEY, () => UserService.getUsers());

export const useAddUser = (reset: () => void) =>
  useMutation<UserDTO, AxiosError, CreateUserDTO>(
    'addUser',
    (data) => UserService.createUser(data),
    {
      onSuccess: async () => {
        reset();
        await queryClient.refetchQueries(USERS_KEY);
        message.success('Dodano nowego użytkownika');
      },
      onError: handleError,
    },
  );

export const useReports = (startDate: string, endDate: string) =>
  useQuery<UserStatsDTO[]>(['reports', startDate, endDate], () =>
    UserService.getReport(startDate, endDate),
  );

export const useActivity = (startDate: string, endDate: string) =>
  useQuery<ActivityDTO[]>(['activity', startDate, endDate], () =>
    UserService.getActivity(startDate, endDate),
  );
