import { Auth0DecodedHash, Auth0Error, WebAuth } from 'auth0-js';

import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_CONNECTION,
  AUTH0_DOMAIN,
  AUTH0_REDIRECT_URI,
  DEFAULT_ERROR_MESSAGE,
  ROUTES,
} from '~/constants';

import * as Auth0TokensUtils from '~/utils/auth0-tokens';

// https://manage.auth0.com/#/applications/
// If you want to update it please, open the link above,
// choose application and edit "Allowed Logout URLs" in all applications!
const VALID_LOGOUT_URLS = [ROUTES.LOGIN, ROUTES.ERROR];

export interface IAuthenticate {
  name?: string;
  email: string;
  password: string;
}

export interface ForgotPassword {
  email: string;
}

class _AuthService {
  private webAuth = new WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: AUTH0_REDIRECT_URI,
    audience: AUTH0_AUDIENCE,
    responseType: 'token id_token',
    scope: 'openid email profile phone',
  });

  loginWithAuth0() {
    this.webAuth.authorize({
      language: 'pl',
    });
  }

  saveTokensToLocalStorage = (authResult: Auth0DecodedHash): void => {
    const { accessToken, idToken } = authResult;

    if (accessToken && idToken) {
      const time = (authResult.expiresIn || 0) * 1000 + new Date().getTime();

      Auth0TokensUtils.setAuthTokens({
        accessToken,
        idToken,
        expiresAt: time,
      });
    }
  };

  async logout(returnTo: string = ROUTES.LOGIN): Promise<void> {
    Auth0TokensUtils.clearAuthTokens();

    return new Promise((resolve) => {
      if (returnTo) {
        if (!returnTo.startsWith('http')) {
          returnTo = window.location.origin + returnTo;
        }

        const isValidUrl = !!VALID_LOGOUT_URLS.find((url) =>
          returnTo.includes(url),
        );

        if (!isValidUrl) {
          throw new Error('Provided "returnTo" url is not whitelisted.');
        }
      }

      this.webAuth.logout({ returnTo });

      // webAuth.logout doesn't return a promise and it needs some time
      // to resolve, that's why we need this timeout
      setTimeout(() => resolve(), 1000);
    });
  }

  renewToken(): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      this.webAuth.checkSession(
        {},
        (error: null | Auth0Error, result: Auth0DecodedHash) => {
          if (error) {
            return reject(error);
          }

          this.saveTokensToLocalStorage(result);

          return resolve(result);
        },
      );
    });
  }

  getCurrentAuthTokens() {
    return Auth0TokensUtils.loadAuthTokens();
  }

  handleAuthentication(): Promise<Auth0DecodedHash> {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.saveTokensToLocalStorage(authResult);

          return resolve(authResult);
        } else if (err) {
          return reject(err);
        }

        return reject(DEFAULT_ERROR_MESSAGE);
      });
    });
  }

  parseAuth0Error(error: Auth0Error): Error {
    // Depending on the error type, Auth0 returns different error objects. ¯\_(ツ)_/¯
    // We handle most of the errors with frontend validations and errors like
    // "Password is based on user information." or "The user already exists."
    // contain the most descriptive message as string in error.description. However, there
    // can be some cases when error.description is an object and the only message is
    // stored either in error.error_description or error.errorDescription.
    // Common error types: https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
    return typeof error.description === 'string'
      ? Error(error.description)
      : Error(error.error_description ?? error.errorDescription);
  }

  loginWithEmailAndPassword(data: IAuthenticate): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.crossOriginAuthentication.login(
        {
          email: data.email,
          password: data.password,
        },
        (error: null | Auth0Error) =>
          error ? reject(this.parseAuth0Error(error)) : resolve(),
      );
    });
  }

  forgotPassword(data: ForgotPassword): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          connection: AUTH0_CONNECTION,
          email: data.email,
        },
        (error: null | Auth0Error) =>
          error ? reject(this.parseAuth0Error(error)) : resolve(),
      );
    });
  }

  signupWithEmailAndPassword(data: IAuthenticate): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.signup(
        {
          connection: AUTH0_CONNECTION,
          email: data.email,
          password: data.password,
          // Auth0 lacks `name` in object interface, so whole object must be converted to any type
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          name: data.name,
        },
        (error: null | Auth0Error) =>
          error ? reject(this.parseAuth0Error(error)) : resolve(),
      );
    });
  }
}

const AuthService = new _AuthService();

export { AuthService, _AuthService };
