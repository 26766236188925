import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import { isString, startCase, toLower } from 'lodash';
import { Department } from '~/dtos';

const { Option } = Select;

export interface Props {
  className?: string;
  selectedDepartment?: string | null;
  onSelect?: (department: Department) => void;
  onClear?: () => void;
  style?: CSSProperties;
}

const departments = Object.values(Department);

export const DepartmentSelect: React.FC<Props> = (props) => {
  return (
    <Select
      showSearch
      allowClear
      value={props.selectedDepartment}
      style={{ width: 170, ...props.style }}
      placeholder='Wybierz placówkę'
      optionFilterProp='children'
      onChange={props.onSelect}
      onClear={props.onClear}
      filterOption={(input, option) => {
        if (option && option.children && input) {
          const parsedName = isString(option.children)
            ? option.children
            : option.children[0];

          return parsedName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }

        return false;
      }}
    >
      {departments.map((department) => (
        <Option value={department} key={department}>
          {startCase(toLower(department))}
        </Option>
      ))}
    </Select>
  );
};
