import { Button, Form, Input, message, Modal, Upload } from 'antd';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UploadOutlined } from '@ant-design/icons';
import { UploadDocumentResponseDTO, VisitDTO } from '~/dtos';
import { DocumentService } from '~/services/Document';
import { CloseOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useCreateVisitFile } from '~/queries/useVisit';
import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd/lib/upload/interface';

interface Props {
  visible: boolean;
  onClose: () => void;
  visit: VisitDTO;
}

interface FormSchema {
  name: string;
  description?: string;
}

interface UploadedFile extends UploadDocumentResponseDTO {
  fileName: string;
  uid: string;
}

const schema = yup.object().shape({
  name: yup.string().required('Nazwa pliku jest wymagana'),
  description: yup.string(),
});

const beforeUpload = (file: RcFile) => {
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error('Plik musi być mniejszy niż 20mb');
  }

  return isLt20M;
};

export const DocumentModal = ({ visible, onClose, visit }: Props) => {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [fileList, setFileList] = useState<Array<UploadFile<File>>>([]);
  const [fileToResponse, setFileToResponse] = useState<
    Record<string, UploadDocumentResponseDTO>
  >({});
  const { handleSubmit, control, formState, reset } = useForm<FormSchema>({
    resolver: yupResolver(schema),
  });

  const handleClose = useCallback(() => {
    reset();
    setUploadedFile(null);
    setFileToResponse({});
    setFileList([]);
    onClose();
  }, [onClose, reset]);

  const { mutate, isLoading } = useCreateVisitFile(visit.id, () => {
    handleClose();
  });

  const onSubmit = useCallback(
    (data: FormSchema) => {
      if (uploadedFile) {
        mutate({
          name: data.name,
          description: data.description,
          fileName: uploadedFile.fileName,
          filePath: uploadedFile.path,
        });
      }
    },
    [mutate, uploadedFile],
  );

  return (
    <Modal
      title='Formularz dodawnia nowego dokumentu'
      visible={visible}
      onCancel={() => handleClose()}
      okButtonProps={{
        onClick: handleSubmit(onSubmit),
        loading: isLoading,
      }}
      okText='Dodaj dokument'
      cancelText='Anuluj'
    >
      <Form layout='vertical'>
        <Form.Item
          label='Nazwa'
          required
          validateStatus={formState.errors.name ? 'error' : 'validating'}
        >
          <Controller
            name='name'
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: '0' }}
          label='Opcjonalny opis pliku'
          validateStatus={formState.errors.description ? 'error' : 'validating'}
        >
          <Controller
            name='description'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea autoSize {...field} value={field.value || ''} />
            )}
          />
        </Form.Item>

        <div style={{ width: '100%' }}>
          <Upload<File>
            listType='picture'
            accept='application/pdf'
            method='PUT'
            beforeUpload={beforeUpload}
            fileList={fileList}
            onChange={(data) => {
              if (!data.file.name.includes('.pdf')) {
                message.error(
                  `Plik "${data.file.name}" nie został dodany. Plik musi być w formacie PDF`,
                  3000,
                );
                return Promise.reject('Plik musi być w formacie PDF');
              }

              setFileList(data.fileList);
              const uploaded = data.fileList.filter((f) => f.status === 'done');

              if (uploaded.length > 0) {
                const { name, uid } = uploaded[0];

                if (fileToResponse[name]) {
                  setUploadedFile({
                    ...fileToResponse[name],
                    fileName: name,
                    uid,
                  });
                }
              }

              return Promise.resolve();
            }}
            maxCount={1}
            showUploadList={{
              removeIcon: () => (
                <CloseOutlined
                  style={{ fontSize: '10px', verticalAlign: 'center' }}
                />
              ),
            }}
            onRemove={() => {
              setUploadedFile(null);
            }}
            action={async (file) => {
              const data = await DocumentService.getUploadUrl({
                entityId: visit.id,
                type: 'visit',
                fileName: file.name,
              });

              setFileToResponse((el) => ({
                ...el,
                [file.name]: data,
              }));

              return data.url;
            }}
          >
            <Button icon={<UploadOutlined />} style={{ marginTop: '10px' }}>
              {uploadedFile ? 'Zamień' : 'Dodaj'} dokument
            </Button>
          </Upload>
        </div>
      </Form>
    </Modal>
  );
};
