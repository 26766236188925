import React, { useMemo } from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ScatterChart,
  Legend,
  Scatter,
} from 'recharts';
import { useVisitChartsData } from '~/queries/useVisit';
import { VisitDTO } from '~/dtos';
import { Skeleton } from 'antd';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';

interface Props {
  visit: VisitDTO;
}

interface ChartUiData {
  weights: Array<{
    value: number;
    time: number;
    kg: boolean;
  }>;
  pulses: Array<{
    value: number;
    time: number;
  }>;
}

export const Chart = ({ visit }: Props) => {
  const { data, isLoading } = useVisitChartsData(visit.id);

  const chartsData = useMemo(() => {
    if (!data) {
      return {
        weights: [],
        pulses: [],
        temperature: [],
      };
    }

    const parsedData = data.data.reduce<ChartUiData>(
      (acc, el) => {
        if (el.pulse) {
          acc.pulses.push({
            value: el.pulse,
            time: +new Date(el.createdAt),
          });
        }

        if (el.weight) {
          acc.weights.push({
            value: el.weight,
            time: +new Date(el.createdAt),
            kg: true,
          });
        }

        return acc;
      },
      {
        weights: [],
        pulses: [],
      },
    );

    return {
      weights: sortBy(parsedData.weights, 'time'),
      pulses: sortBy(parsedData.pulses, 'time'),
    };
  }, [data]);

  return (
    <Skeleton loading={isLoading} active>
      <ResponsiveContainer width='100%' height='100%'>
        <ScatterChart
          width={800}
          height={400}
          margin={{
            top: 30,
            right: 20,
            bottom: 10,
            left: 20,
          }}
        >
          <XAxis
            domain={['auto', 'auto']}
            dataKey='time'
            type='number'
            name='Data badania'
            tickFormatter={(val) => dayjs(val).format('DD MMMM YYYY')}
          />
          <YAxis dataKey='value' name='Wartość' />
          <Tooltip
            formatter={(value: any, name: any, props: any) => {
              if (name === 'Data badania') {
                return [
                  dayjs(value as number).format('DD MMM YYYY hh:mm'),
                  'Data badania',
                ];
              } else if (name === 'Wartość') {
                return [
                  props.payload.kg ? `${value} kg` : value,
                  props.payload.kg ? 'Waga' : 'Tętno',
                ];
              }

              return [value, name];
            }}
          />
          <Legend />
          <Scatter
            data={chartsData.pulses}
            line={{ stroke: '#c2c2c2' }}
            lineJointType='monotoneX'
            lineType='joint'
            fill='#36c6df'
            name='Tętno'
          />
          <Scatter
            data={chartsData.weights}
            line={{ stroke: '#c2c2c2' }}
            fill='#77d9ad'
            lineJointType='monotoneX'
            lineType='joint'
            name='Waga'
          />
        </ScatterChart>
      </ResponsiveContainer>
    </Skeleton>
  );
};
