import { Avatar, Button, Card, message } from 'antd';
import Meta from 'antd/es/card/Meta';
import React, { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import { UserDTO } from '~/dtos';
import { AuthService } from '~/services/Auth';
import { UserService } from '~/services/User';
import dateUtils from '~/utils/date';

export const UserProfileView: React.FC = () => {
  const { isLoading, data } = useQuery<UserDTO>('user', () =>
    UserService.getMe(),
  );

  const { isLoading: passwordLoading, mutate } = useMutation(
    'changePassword',
    () => {
      if (!data) {
        return Promise.reject('Brakuje emaila.');
      }

      return AuthService.forgotPassword({ email: data?.email });
    },
    {
      onSuccess: async () => {
        message.success('Wysłaliśmy maila z linkiem do resetu hasła.');
      },
      onError: async (error: Error) => {
        message.error(`Coś poszło nie tak: ${error.message}`);
      },
    },
  );

  const handleForgotPassword = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Card loading={isLoading}>
      <Meta
        avatar={<Avatar size={64} src={data?.avatarUrl} />}
        title={`Witaj ${data?.name}! (${data?.email})`}
        description={`Data utworzenia konta: ${
          data ? dateUtils.format(data.createdAt) : '?'
        }.`}
      />
      <div style={{ marginTop: 16 }}>
        <Button
          loading={passwordLoading}
          onClick={handleForgotPassword}
          style={{ marginRight: 8 }}
        >
          Chcę zmienić hasło do konta
        </Button>
        <Button onClick={() => AuthService.logout()}>Wyloguj</Button>
      </div>
    </Card>
  );
};
