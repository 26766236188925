import { Button, Card, DatePicker, Divider, Form, Input, Select } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import * as yup from 'yup';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddPatient, useUpdatePatient } from '~/queries/usePatients';
import { DepartmentSelect } from '~/components/DepartmentSelect';
import { useHistory, useLocation } from 'react-router-dom';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import { PatientDTO } from '~/dtos';
import { GoBackButton } from '~/components/GoBackButton';
import moment from 'moment';

const from: string[] = [
  'z polecenia',
  'ze strony internetowej',
  'z Google',
  'z Facebooka',
  'z instagrama',
  'inne',
];

const visitSchema = yup.object().shape({
  department: yup.string().required('Placówka jest wymagana'),
  startedAt: yup.string().required('Data rozpoczęcia jest wymagana'),
});

const schema = yup.object().shape({
  firstName: yup
    .string()
    .test(
      'correctFormat',
      'Imie nie moze zawierać spacji',
      (value: string) => !value.includes(' '),
    )
    .required('Imie jest wymagane')
    .test(
      'len',
      'Imię musi składać się z conajmniej 3 znaków.',
      (val) => (val?.length || 0) >= 3,
    ),
  dateOfBirth: yup.string().required('Data urodzenia jest wymagana'),
  lastName: yup
    .string()
    .test(
      'correctFormat',
      'Nazwisko nie moze zawierać spacji',
      (value: string) => !value.includes(' '),
    )
    .required('Nazwisko jest wymagane')
    .test(
      'len',
      'Nazwisko musi składać się z conajmniej 3 znaków.',
      (val) => (val?.length || 0) >= 3,
    ),
  personalIdentityNumber: yup
    .string()
    .required('Pesel jest wymagany')
    .test(
      'len',
      'Pesel musi składać się z conajmniej 11 znaków.',
      (val) => (val?.length || 0) >= 11,
    )
    .test(
      'correctFormat',
      'Pesel nie moze zawierać spacji',
      (value: string) => !value.includes(' '),
    ),
  street: yup.string().required('Ulica jest wymagana'),
  from: yup
    .string()
    .required('Informacja skąd przyszedł pacjent jest wymagana'),
  city: yup.string().required('Miasto jest wymagane'),
  zip: yup
    .string()
    .matches(/^[0-9-]+$/, 'Kod pocztowy musi zawierać tylko liczby')
    .required('Kod pocztowy jest wymagany')
    .test(
      'len',
      'Kod pocztowy musi składać się z conajmniej 3 znaków.',
      (val) => (val?.length || 0) >= 3,
    ),
  phoneNumber: yup.string().optional().nullable(),
  email: yup
    .string()
    .email('To nie wygląda na adres email')
    .optional()
    .nullable(),
  phoneNumberEntitledContactPerson: yup.string().optional().nullable(),
  phoneNumberLegalGuardian: yup.string().optional().nullable(),
});

const Row: React.FC = (props) => (
  <div
    style={{
      alignItems: 'center',
      gap: 8,
      marginBottom: 8,
      width: '100%',
      display: 'inline-flex',
    }}
  >
    {props.children}
  </div>
);

export const PatientFormView: React.FC = () => {
  const history = useHistory();
  const location = useLocation<{ patient?: PatientDTO }>();
  const isEditing = Boolean(location.state?.patient);
  const { handleSubmit, control, formState, setValue } = useForm({
    resolver: yupResolver(isEditing ? schema : schema.concat(visitSchema)),
  });

  useEffect(() => {
    if (location.state?.patient) {
      const { patient } = location.state;
      setValue('firstName', patient.firstName);
      setValue('lastName', patient.lastName);
      setValue('personalIdentityNumber', patient.personalIdentityNumber);
      setValue('dateOfBirth', moment(patient.dateOfBirth));
      setValue('street', patient.street);
      setValue('city', patient.city);
      setValue('email', patient.email);
      setValue('zip', patient.zip);
      setValue('from', patient.from);
      setValue('phoneNumber', patient.phoneNumber);
      setValue('phoneNumberLegalGuardian', patient.phoneNumberLegalGuardian);
      setValue(
        'phoneNumberEntitledContactPerson',
        patient.phoneNumberEntitledContactPerson,
      );
    }
  }, [setValue, location.state]);

  const { mutate: addPatient, isLoading } = useAddPatient((patient) => {
    const visit = patient.visits[0];

    if (visit) {
      history.push(
        createPatientVisitRoute(
          ROUTES.dashboard.patient.visit.ROOT,
          patient.id,
          visit.id,
        ),
      );
    }
  });

  const { mutate: updatePatient, isLoading: isUpdating } = useUpdatePatient(
    location.state?.patient?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data) => {
      if (data.dateOfBirth) {
        data.dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD');
      }

      if (isEditing) {
        updatePatient(data);
      } else {
        addPatient(data);
      }
    },
    [addPatient, updatePatient, isEditing],
  );

  const renderInput = useCallback(
    (label: string, name: string, required: boolean, props?: InputProps) => (
      <Form.Item
        label={label}
        required={required}
        style={{ width: '100%' }}
        validateStatus={formState.errors[name] ? 'error' : 'validating'}
        help={
          formState.errors[name] ? formState.errors[name]?.message : undefined
        }
      >
        <Controller
          name={name}
          control={control}
          defaultValue=''
          render={({ field }) => <Input {...field} {...props} />}
        />
      </Form.Item>
    ),
    [formState, control],
  );

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawania'
          } danych pacjenta`}
        />
      }
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Row>
          {renderInput('Imię', 'firstName', true)}
          {renderInput('Nazwisko', 'lastName', true)}
        </Row>
        <Row>
          {renderInput('Pesel', 'personalIdentityNumber', true, {
            type: 'number',
            min: 0,
          })}
          <Form.Item
            label='Data urodzenia'
            required
            style={{ width: '100%' }}
            validateStatus={
              formState.errors.dateOfBirth ? 'error' : 'validating'
            }
            help={
              formState.errors.dateOfBirth
                ? formState.errors.dateOfBirth.message
                : undefined
            }
          >
            <Controller
              name='dateOfBirth'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <DatePicker
                  placeholder='Wybierz date'
                  onChange={field.onChange}
                  value={field.value}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Form.Item>
          {renderInput('Number telefonu', 'phoneNumber', false)}
        </Row>
        <Row>
          {renderInput(
            'Telefon do opiekuna prawnego',
            'phoneNumberLegalGuardian',
            false,
          )}
          {renderInput(
            'Telefon do osoby upożnionej do kontaktu',
            'phoneNumberEntitledContactPerson',
            false,
          )}
          {renderInput('Adres e-mail', 'email', false)}
        </Row>
        <Row>
          {renderInput('Ulica', 'street', true)}
          {renderInput('Miasto', 'city', true)}
          {renderInput('Kod pocztowy', 'zip', true)}
        </Row>
        <Row>
          <Form.Item
            label='Skąd przyszedł pacjent'
            required
            style={{ width: '100%' }}
            validateStatus={formState.errors.from ? 'error' : 'validating'}
            help={
              formState.errors.from ? formState.errors.from.message : undefined
            }
          >
            <Controller
              name='from'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder='Wybierz skąd przyszedł pacjent'
                  onChange={field.onChange}
                  value={field.value}
                  allowClear
                >
                  {from.map((item, idx) => (
                    <Select.Option key={idx} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </Row>

        {!isEditing && (
          <>
            <Divider>Dane o wizycie w placówce</Divider>
            <Row>
              <Form.Item
                label='Placówka'
                required
                style={{ width: '100%' }}
                validateStatus={
                  formState.errors.department ? 'error' : 'validating'
                }
                help={
                  formState.errors.department
                    ? formState.errors.department.message
                    : undefined
                }
              >
                <Controller
                  name='department'
                  control={control}
                  render={({ field }) => (
                    <DepartmentSelect
                      onSelect={field.onChange}
                      selectedDepartment={field.value}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Form.Item>
              <Form.Item
                label='Data przyjścia do placówki'
                required
                style={{ width: '100%' }}
                validateStatus={
                  formState.errors.startedAt ? 'error' : 'validating'
                }
                help={
                  formState.errors.startedAt
                    ? formState.errors.startedAt.message
                    : undefined
                }
              >
                <Controller
                  name='startedAt'
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholder='Wybierz date'
                      onChange={field.onChange}
                      value={field.value}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Form.Item>
            </Row>
          </>
        )}
        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading || isUpdating}
        >
          {isEditing ? 'Edytuj pacjenta' : 'Dodaj pacjenta'}
        </Button>
      </Form>
    </Card>
  );
};
