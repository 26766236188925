import RequestService from './Request';
import { NurseObservationDTO, UpdateNurseObservationDTO } from '~/dtos';

class _NurseObservationService {
  updateNurseObservation(
    id: string,
    data: UpdateNurseObservationDTO,
  ): Promise<NurseObservationDTO> {
    return RequestService.client
      .patch<NurseObservationDTO>(`/nurse-observations/${id}`, data)
      .then((response) => response.data);
  }

  getNurseObservation(id: string): Promise<NurseObservationDTO> {
    return RequestService.client
      .get<NurseObservationDTO>(`/nurse-observations/${id}`)
      .then((response) => response.data);
  }
}

const NurseObservationService = new _NurseObservationService();

export { NurseObservationService };
