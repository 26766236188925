import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextArea from 'antd/es/input/TextArea';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Form, Input, Space, Typography, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { message } from 'antd';

import { createPatientVisitRoute, ROUTES } from '~/constants';
import { useAddExamination, useUpdateExamination } from '~/queries/useVisit';
import {
  CreateDocumentDTO,
  ExaminationDTO,
  PatientDTO,
  VisitDTO,
} from '~/dtos';
import {
  EXAMINATION_TYPE,
  ExaminationSelect,
} from '~/components/ExaminationSelect';
import { DocumentService } from '~/services/Document';
import { GoBackButton } from '~/components/GoBackButton';

interface FormSchema {
  note?: string;
  type: string;
  documents: CreateDocumentDTO[];
}

const schema = yup.object().shape({
  note: yup.string().optional(),
  documents: yup.array().min(1).required('Musisz dodać conajmniej jeden plik'),
  type: yup.string().required('Nazwa badania jest wymagana.'),
});

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const ExaminationForm = ({ visit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation<{ examination?: ExaminationDTO }>();
  const isEditing = Boolean(location.state?.examination);

  const [selectedOther, setSelectedOther] = useState(false);
  const [fileToPath, setFileToPath] = useState<Record<string, string>>({});
  const { handleSubmit, control, formState, setValue, watch } =
    useForm<FormSchema>({
      resolver: yupResolver(schema),
      defaultValues: {
        documents: [],
      },
    });

  useEffect(() => {
    if (location.state?.examination) {
      const { examination } = location.state;
      setValue('note', examination.note || '');
      setValue(
        'documents',
        examination.documents.map((el) => ({
          filePath: el.filePath,
          fileName: el.fileName,
        })),
      );
      setValue('type', examination.type);
    }
  }, [location.state, setValue]);

  const formDocuments: CreateDocumentDTO[] = watch('documents');
  const { mutate: addExamination, isLoading } = useAddExamination(
    visit.id,
    () => {
      history.push(
        createPatientVisitRoute(
          ROUTES.dashboard.patient.visit.EXAMINATIONS,
          patient.id,
          visit.id,
        ),
      );
    },
  );

  const { mutate: updateExamination } = useUpdateExamination(
    visit.id,
    location.state?.examination?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data: FormSchema) => {
      if (isEditing) {
        updateExamination(data);
      } else {
        addExamination({
          type: data.type,
          note: data.note || null,
          documents: data.documents,
        });
      }
    },
    [addExamination, updateExamination, isEditing],
  );

  const {
    errors: { type, note },
  } = formState;

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawania'
          } wykonanych badań`}
        />
      }
      style={{ marginTop: 16 }}
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        {!selectedOther && (
          <Form.Item
            label='Typ badania'
            required
            validateStatus={type ? 'error' : 'validating'}
            help={type ? type.message : undefined}
          >
            <Controller
              name='type'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <ExaminationSelect
                  onSelect={(change) => {
                    field.onChange(change);

                    if (change === EXAMINATION_TYPE.Inne) {
                      setSelectedOther(true);
                      setValue('type', '');
                    }
                  }}
                  selectedExamination={field.value}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Form.Item>
        )}

        {selectedOther && (
          <Form.Item label='Wpisz ręcznie typ badania' required>
            <Controller
              name='type'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <Input.Group compact style={{ display: 'flex' }}>
                  <Input
                    onChange={field.onChange}
                    value={field.value}
                    allowClear
                  />
                  <Button onClick={() => setSelectedOther(false)}>
                    Wróć do listy typów
                  </Button>
                </Input.Group>
              )}
            />
          </Form.Item>
        )}

        <Form.Item
          label='Opis nieprawidłowych wyników - opcjonalne'
          validateStatus={note ? 'error' : 'validating'}
          help={note ? note.message : undefined}
        >
          <Controller
            name='note'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea
                autoSize
                placeholder='Opisz wszelkie nieprawidłowości jeśli takie wystąpiły'
                onChange={field.onChange}
                value={field.value}
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>

        <Space direction='vertical' style={{ width: '100%' }} size='large'>
          <Upload
            listType='picture'
            maxCount={10}
            multiple
            defaultFileList={
              location.state?.examination?.documents.map((d) => ({
                name: d.fileName,
                uid: d.id,
                type: 'application/pdf',
              })) || []
            }
            accept='application/pdf'
            style={{ width: '100%' }}
            method='PUT'
            onRemove={(file) => {
              setValue(
                'documents',
                formDocuments.filter((f) => f.fileName !== file.name),
              );
            }}
            onChange={(data) => {
              const uploaded = data.fileList.filter((f) => f.status === 'done');
              setValue(
                'documents',
                uploaded.map((el) => ({
                  filePath: fileToPath[el.name],
                  fileName: el.name,
                })),
              );
            }}
            action={async (file) => {
              if (!file.name.includes('.pdf')) {
                message.error(
                  `Plik "${file.name}" nie został dodany. Plik musi być w formacie PDF`,
                  3000,
                );
                return Promise.reject('Plik musi być w formacie PDF');
              }

              const data = await DocumentService.getUploadUrl({
                entityId: visit.id,
                type: 'examination',
                fileName: file.name,
              });

              setFileToPath((el) => ({
                ...el,
                [file.name]: data.path,
              }));

              return data.url;
            }}
          >
            <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
              Wyślij pliki (Maksymalnie: 10)
            </Button>
          </Upload>

          {formDocuments.length === 0 && (
            <Typography.Text type='danger'>
              Aby dodać badanie musisz dodać conajmniej jednen plik!
            </Typography.Text>
          )}

          <Button
            block
            type='primary'
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={formDocuments.length === 0}
          >
            {isEditing ? 'Edytuj' : 'Dodaj'} badanie
          </Button>
        </Space>
      </Form>
    </Card>
  );
};
