import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import { useCreateNurseObservations } from '~/queries/useVisit';
import {
  CreateNurseObservationDTO,
  PatientDTO,
  NurseObservationDTO,
  VisitDTO,
} from '~/dtos';
import { useUpdateNurseObservation } from '~/queries/useNurseObservation';
import TextArea from 'antd/lib/input/TextArea';
import { GoBackButton } from '~/components/GoBackButton';

const schema = yup.object().shape({
  content: yup.string().required('To pole jest wymagane.'),
});

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const NurseObservationForm = ({ visit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation<{ nurseObservation?: NurseObservationDTO }>();
  const isEditing = Boolean(location.state?.nurseObservation);

  const formUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.NURSE_OBSERVATIONS,
    patient.id,
    visit.id,
  );

  const { handleSubmit, control, formState, setValue } =
    useForm<CreateNurseObservationDTO>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (location.state?.nurseObservation) {
      const { nurseObservation } = location.state;
      setValue('content', nurseObservation.content);
    }
  }, [location.state, setValue]);

  const { mutate: createNurseObservation, isLoading } =
    useCreateNurseObservations(visit.id, () => {
      history.push(formUrl);
    });

  const { mutate: updateNurseObservation } = useUpdateNurseObservation(
    location.state?.nurseObservation?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data) => {
      if (isEditing) {
        updateNurseObservation(data);
      } else {
        createNurseObservation(data);
      }
    },
    [updateNurseObservation, createNurseObservation, isEditing],
  );

  const { errors } = formState;

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawania nowej'
          } obserwacji pielęgniarskiej`}
        />
      }
      style={{ marginTop: 16 }}
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Form.Item
          label='Treść notatki'
          required
          validateStatus={formState.errors?.content ? 'error' : 'validating'}
          help={errors?.content?.message}
        >
          <Controller
            name='content'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea autoSize {...field} value={field.value || ''} />
            )}
          />
        </Form.Item>
        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          {isEditing ? 'Edytuj' : 'Dodaj'} obserwacje
        </Button>
      </Form>
    </Card>
  );
};
