import React from 'react';
import { ExaminationDTO, VisitDTO } from '~/dtos';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useVisitExaminations } from '~/queries/useVisit';
import { fullDateWithMonthFormat } from '~/constants';

const columns: ColumnsType<ExaminationDTO> = [
  {
    title: 'Ostatnio dodane notatki do wyników badań',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 200,
  },
  {
    title: 'Data wykonania',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 400,
    render: (_, examination) =>
      `${fullDateWithMonthFormat(examination.createdAt)} (${dayjs(
        examination.createdAt,
      ).fromNow()})`,
  },
];

export interface Props {
  visit: VisitDTO;
}

export const ExaminationsTable = ({ visit }: Props) => {
  const { data, isLoading } = useVisitExaminations(visit.id);

  const examinationsWithNotes = data?.filter((el) => el.note);

  if (examinationsWithNotes?.length === 0) {
    return null;
  }

  return (
    <Table<ExaminationDTO>
      rowKey='id'
      size='small'
      columns={columns}
      loading={isLoading}
      dataSource={data?.filter((el) => el.note)}
      bordered
    />
  );
};
