import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { PatientDTO, VisitDTO } from '~/dtos';
import { Chart } from './Chart';
import { ExaminationsTable } from './Examinations';
import { PatientDetailsCard } from './PatientDetailsCard';
import { VisitDetailsCard } from './VisitDetailsCard';
import { DoctorDetails } from './DoctorDetailsCard';

export interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const PatientDetailsView = ({ patient, visit }: Props) => {
  return (
    <div style={{ padding: 16 }}>
      <Typography.Title level={2}>Informacje</Typography.Title>

      <Space direction='vertical' style={{ width: '100%' }} size='large'>
        <Row gutter={16}>
          <Col span={12}>
            <Space direction='vertical' style={{ width: '100%' }}>
              <PatientDetailsCard patient={patient} />
              <VisitDetailsCard visit={visit} patient={patient} />
              <DoctorDetails patient={patient} visit={visit} />
            </Space>
          </Col>
          <Col span={12}>
            <Chart visit={visit} />
          </Col>
        </Row>
        <ExaminationsTable visit={visit} />
      </Space>
    </div>
  );
};
