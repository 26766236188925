import { message } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from '~/constants';
import { NurseObservationDTO, UpdateNurseObservationDTO } from '~/dtos';
import { NurseObservationService } from '~/services/NurseObservation';
import { handleError } from './common';

export const NURSE_OBSERVATION = 'nurse-observation';
export const UPDATE_NURSE_OBSERVATION = 'update_nurse-observation';

export const useNurseObservation = (id: string) =>
  useQuery<NurseObservationDTO>([NURSE_OBSERVATION, id], () =>
    NurseObservationService.getNurseObservation(id),
  );

export const useUpdateNurseObservation = (
  id: string,
  onSuccess?: (nurseObservation: NurseObservationDTO) => void,
) =>
  useMutation<NurseObservationDTO, AxiosError, UpdateNurseObservationDTO>(
    UPDATE_NURSE_OBSERVATION,
    (data: UpdateNurseObservationDTO) =>
      NurseObservationService.updateNurseObservation(id, data),
    {
      onSuccess: async (nurseObservation) => {
        await queryClient.refetchQueries([NURSE_OBSERVATION, id]);
        message.success('Edytowano obserwację pielęgniarską');

        if (onSuccess) {
          onSuccess(nurseObservation);
        }
      },
      onError: handleError,
    },
  );
