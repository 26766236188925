import { init } from '@sentry/react';

import {
  COMMIT_HASH,
  PROD_ENV,
  SENTRY_DSN,
  SENTRY_ENV_NAME,
  SENTRY_NORMALIZE_DEPTH,
} from '~/constants';

export const initSentry = () => {
  init({
    release: COMMIT_HASH,
    dsn: SENTRY_DSN,
    enabled: PROD_ENV,
    environment: SENTRY_ENV_NAME,
    normalizeDepth: SENTRY_NORMALIZE_DEPTH,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
};
