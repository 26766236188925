import React, { useCallback, useEffect } from 'react';
import { PatientDTO, VisitDTO } from '~/dtos';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Form, Button, message } from 'antd';
import { useUpdateVisit } from '~/queries/useVisit';

export interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

interface Form {
  recommendations: string;
  diagnosis: string;
}

const schema = yup.object().shape({
  recommendations: yup.string().optional(),
  diagnosis: yup.string().optional(),
});

export const VisitForm = ({ visit, patient }: Props) => {
  const { handleSubmit, control, formState, reset, setValue } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      recommendations: visit.recommendations || '',
      diagnosis: visit.diagnosis || '',
    },
  });

  useEffect(() => {
    setValue('recommendations', visit.recommendations || '');
    setValue('diagnosis', visit.diagnosis || '');
  }, [setValue, visit]);

  const { mutate, isLoading } = useUpdateVisit(
    visit.id,
    patient.id,
    async (visit) => {
      reset();
      setValue('recommendations', visit.recommendations || '');
      setValue('diagnosis', visit.diagnosis || '');
      await message.success('Dane zostały pomyślnie zapisane.');
    },
  );

  const onSubmit = useCallback(
    (data) => {
      mutate(data);
    },
    [mutate],
  );

  return (
    <Form
      layout='vertical'
      onSubmitCapture={handleSubmit(onSubmit)}
      style={{ marginBottom: 16 }}
    >
      <Form.Item label='Rozpoznanie:'>
        <Controller
          name='diagnosis'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <Input.TextArea {...field} disabled={!!visit.endedAt} />
          )}
        />
      </Form.Item>
      <Form.Item label='Zalecenia:'>
        <Controller
          name='recommendations'
          control={control}
          defaultValue=''
          render={({ field }) => (
            <Input.TextArea {...field} disabled={!!visit.endedAt} />
          )}
        />
      </Form.Item>

      {Object.values(formState.dirtyFields).length ? (
        <Button htmlType='submit' type='primary' loading={isLoading}>
          Zapisz zmiany
        </Button>
      ) : null}
    </Form>
  );
};
