import { TagProps } from 'antd';

export enum Role {
  ADMIN = 'admin',
  DOCTOR = 'doctor',
  NURSE = 'nurse',
  RECEPTIONIST = 'receptionist',
  DIETICIAN = 'dietician',
  PSYCHOLOGIST = 'psychologist',
}

export const ROLE_TO_HUMAN = {
  [Role.ADMIN]: 'Administrator',
  [Role.DOCTOR]: 'Doktor',
  [Role.NURSE]: 'Pielęgniarka',
  [Role.RECEPTIONIST]: 'Recepcja',
  [Role.DIETICIAN]: 'Dietyk',
  [Role.PSYCHOLOGIST]: 'Psycholog',
};

export interface CreateUserDTO {
  username: string;
  password: string;
  isAdmin: boolean;
  email: string;
  role: Role;
}

export interface UpdateUserDTO {
  role: Role;
}

export interface UpdateUserPasswordDTO {
  newPassword: string;
}

export interface UserDTO {
  name: string;
  id: string;
  createdAt: string;
  role: Role;
  avatarUrl: string;
  email: string;
  emailVerified: boolean;
}

export interface UserStatsDTO {
  user: UserDTO;
  addedExaminations: string[];
  addedNurseObservations: string[];
  addedObservations: string[];
  addedEntryObservations: string[];
  addedDrugs: string[];
  addedEpicrisis: string[];
}

export interface ActivityDTO {
  addedById: string;
  id: string;
  createdAt: Date;
  type:
    | 'examination'
    | 'observation'
    | 'entryObservation'
    | 'drug'
    | 'epicrisis';
}

export interface PaginatedResponse<T> {
  count: number;
  items: T[];
}

export interface PatientQuery {
  department?: Department;
  q?: string;
  offset?: number;
  limit?: number;
}

export interface CreatePatientDTO {
  dateOfBirth: Date;
  firstName: string;
  from: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  phoneNumberEntitledContactPerson?: string | null;
  phoneNumberLegalGuardian?: string | null;
  personalIdentityNumber: string;
  street: string;
  city: string;
  zip: string;
  doctorId: string;
}

export interface UpdatePatientDTO {
  dateOfBirth?: string;
  firstName?: string;
  from?: string;
  lastName?: string;
  email?: string | null;
  phoneNumber?: string | null;
  phoneNumberEntitledContactPerson?: string | null;
  phoneNumberLegalGuardian?: string | null;
  personalIdentityNumber?: string;
  street?: string;
  city?: string;
  doctorId?: string | null;
  zip?: string;
}

export interface PatientDTO {
  id: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  from: string;
  personalIdentityNumber: string;
  street: string;
  city: string;
  zip: string;
  createdAt: Date;
  updatedAt: Date;
  email: string | null;
  phoneNumber: string | null;
  phoneNumberEntitledContactPerson: string | null;
  phoneNumberLegalGuardian: string | null;
  doctor: UserDTO | null;
  doctorId: string | null;
  visits: VisitDTO[];
}

export enum Department {
  KONSTANCIN = 'konstancin',
  ZALESIE = 'zalesie',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
}

export const STATUS_TO_HUMAN_STRING: { [key in Status]: string } = {
  [Status.ACTIVE]: 'Trwający',
  [Status.CANCELLED]: 'Anulowany',
  [Status.FINISHED]: 'Zakończony',
};

export const STATUS_TO_COLOR: { [key in Status]: TagProps['color'] } = {
  [Status.ACTIVE]: 'green',
  [Status.CANCELLED]: 'orange',
  [Status.FINISHED]: 'blue',
};

export interface VisitDTO {
  id: string;
  department: Department;
  startedAt: Date;
  recommendations: string | null;
  diagnosis: string | null;
  ledgerIndex: string | null;
  endReason: string | null;
  endedAt: Date | null;
  status: Status;
  createdAt: Date;
  updatedAt: Date;
  excerpt?: DocumentDTO;
}

export interface UpdateVisitDTO {
  recommendations?: string | null;
  diagnosis?: string | null;
  department?: string;
  startedAt?: Date;
}

export interface CreateVisitDTO {
  department: Department;
  startedAt: Date;
}

export interface DocumentDTO {
  id: string;
  fileName: string;
  filePath: string;
  uploadedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface FileDownloadDTO {
  downloadUrl: string;
}

export interface UploadDocumentDTO {
  fileName: string;
  entityId: string;
  type: 'visit' | 'examination';
}

export interface UploadDocumentResponseDTO {
  path: string;
  url: string;
}

export interface CreateDocumentDTO {
  fileName: string;
  filePath: string;
}

export interface CreateDrugDTO {
  name: string;
  morning: string;
  noon: string;
  evening: string;
  night: string;
}

export enum EntryObservationType {
  PSYCH = 'Psychiatryczna',
  DIET = 'Dietetyczna',
  INTERN = 'Internistyczna',
}

export interface DrugDTO {
  id: string;
  name: string;
  morning: string;
  noon: string;
  evening: string;
  night: string;
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateDrugDTO {
  name?: string;
  morning?: string;
  noon?: string;
  evening?: string;
  night?: string;
}

export interface CreateEntryObservationDTO {
  reason: string;
  disease: string;
  type: EntryObservationType;
  afflictions: string;
  hospitalized: string;
  drugs: string;
  state: string;
}

export interface EntryObservationDTO {
  id: string;
  reason: string;
  disease: string;
  type: EntryObservationType;
  afflictions: string;
  hospitalized: string;
  drugs: string;
  state: string;
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateEntryObservationDTO {
  reason?: string;
  disease?: string;
  type?: EntryObservationType;
  afflictions?: string;
  hospitalized?: string;
  drugs?: string;
  state?: string;
}

export enum EpicrisisType {
  PSYCH = 'Psychiatryczna',
  DIET = 'Dietetyczna',
  INTERN = 'Internistyczna',
  PSYCHTER = 'Psychoterapeutyczna',
}

export interface CreateEpicrisisDTO {
  type: EpicrisisType;
  description: string;
  treatment: string;
  state: string;
}

export interface UpdateEpicrisisDTO {
  description?: string;
  treatment?: string;
  state?: string;
}

export interface EpicrisisDTO {
  id: string;
  type: EpicrisisType;
  description: string;
  treatment: string;
  state: string;
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateExaminationDTO {
  note: string | null;
  type: string;
  documents: CreateDocumentDTO[];
}

export interface UpdateExaminationDTO {
  note?: string | null;
  type?: string;
  documents?: CreateDocumentDTO[];
}
export interface ExaminationDTO {
  id: string;
  note: string | null;
  type: string;
  documents: DocumentDTO[];
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface VisitFileDTO {
  id: string;
  description: string;
  name: string;
  createdAt: Date;
  fileName: string;
  filePath: string;
  uploadedBy: UserDTO;
}

export interface CreateVisitFileDTO {
  name: string;
  description?: string;
  fileName: string;
  filePath: string;
}

export interface CreateObservationDTO {
  weight: number | null;
  height: number | null;
  additionalInformation: string | null;
  pulse: number;
  pressure: string;
  createdAt: Date;
  temperature: number;
}

export interface GenerateExcerptDocumentDTO {
  endReason: string;
  endDate: string;
}

export interface CancelVisitDTO {
  endReason: string;
  endedAt: string;
}

export interface GenerateExcerptDocumentResponseDTO {
  filename: string;
  previewUrl: string;
  path: string;
}

export interface ConfirmVisitExitDTO {
  endReason: string;
  endDate: string;
  filePath: string;
  fileName: string;
}

export interface ObservationDTO {
  id: string;
  weight: number | null;
  height: number | null;
  additionalInformation: string | null;
  pulse: number;
  pressure: string;
  temperature: number;
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateObservationDTO {
  weight?: number | null;
  height?: number | null;
  additionalInformation?: string | null;
  pulse?: number;
  pressure?: string;
  temperature?: number;
}

export type ChartDataDTO = Pick<
  ObservationDTO,
  'weight' | 'createdAt' | 'pulse'
>;

export interface ChartsResponseDTO {
  data: ChartDataDTO[];
}

export interface PsychoObservationDTO {
  id: string;
  content: string;
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreatePsychoObservationDTO {
  content: string;
}

export interface UpdatePsychoObservationDTO {
  content: string;
}

export interface NurseObservationDTO {
  id: string;
  content: string;
  addedBy: UserDTO;
  updatedBy: UserDTO;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateNurseObservationDTO {
  content: string;
}

export interface UpdateNurseObservationDTO {
  content: string;
}
