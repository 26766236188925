import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import Head from './Head';

import PrivateRoute from '~/components/PrivateRoute';
import { GenericErrorPage } from '~/components/GenericErrorPage';

import { NOT_FOUND_ERROR_MESSAGE, ROUTES } from '~/constants';

import DashboardView from './dashboard';
import LoginCallbackView from './login-callback';
import LoginView from './login';
import { RootContainer } from './RootContainer';
import ReactGA from 'react-ga4';

const AppViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [location]);

  return (
    <RootContainer>
      <Head />
      <Switch>
        <Route path={ROUTES.ROOT} exact>
          <Redirect to={ROUTES.dashboard.ROOT} />
        </Route>
        <PrivateRoute path={ROUTES.dashboard.ROOT}>
          <DashboardView />
        </PrivateRoute>
        <Route path={ROUTES.ERROR} exact>
          <GenericErrorPage />
        </Route>
        <Route path={ROUTES.LOGIN_CALLBACK} exact>
          <LoginCallbackView />
        </Route>
        <Route path={ROUTES.LOGIN} exact>
          <LoginView />
        </Route>
        <Route>
          <GenericErrorPage error={NOT_FOUND_ERROR_MESSAGE} showGoBackButton />
        </Route>
      </Switch>
    </RootContainer>
  );
};

export default AppViews;
