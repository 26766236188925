import { Button, Card, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_NAME, ROUTES } from '~/constants';
import { useStoreState } from '~/global-state/hooks';
import styles from '~/views/login/styles.scss';
import { useErrorFromUrlParams } from './hooks';
import { chooseFirstDefinedValue, parseErrorWithCode } from './helpers';

const DEFAULT_PROPS = {
  pageTitle: DEFAULT_ERROR_NAME,
  error: DEFAULT_ERROR_NAME,
  errorDescription: DEFAULT_ERROR_MESSAGE,
  showRetryButton: true,
  showHomeButton: false,
  showGoBackButton: false,
};

export interface ErrorPageParams {
  error?: string;
  errorDescription?: string;
  showRetryButton?: boolean;
  showHomeButton?: boolean;
  showGoBackButton?: boolean;
  reloadOnGoBack?: boolean;
  pageTitle?: string;
  code?: number;
}

export interface Props extends Partial<ErrorPageParams> {
  handleReportErrorClick?: () => void;
  onRetryClick?: () => void;
}

const handleRetry = () => {
  window.location.reload();
};

export const GenericErrorPage: React.FC<Props> = ({
  error,
  code,
  errorDescription,
  showHomeButton,
  showRetryButton,
  showGoBackButton,
  reloadOnGoBack,
  pageTitle,
  handleReportErrorClick,
  onRetryClick,
}) => {
  const urlParams = useErrorFromUrlParams();
  const lastLocation = useLastLocation();
  const history = useHistory();
  const isAuthenticated = useStoreState((s) => s.auth.isAuthenticated);

  const handleGoBack = useCallback(() => {
    if (reloadOnGoBack)
      lastLocation && window.location.replace(lastLocation.pathname);
    else history.goBack();
  }, [history, lastLocation, reloadOnGoBack]);

  const title =
    error || code
      ? parseErrorWithCode({ error, code })
      : urlParams.error || DEFAULT_PROPS.error;

  const pageTitleRender =
    pageTitle ||
    urlParams.pageTitle ||
    error ||
    urlParams.error ||
    DEFAULT_PROPS.error;

  const description = errorDescription || urlParams.errorDescription;

  const renderRetryButton = chooseFirstDefinedValue(
    showRetryButton,
    urlParams.showRetryButton,
    DEFAULT_PROPS.showRetryButton,
  );

  const renderHomeButton = chooseFirstDefinedValue(
    showHomeButton,
    urlParams.showHomeButton,
    DEFAULT_PROPS.showHomeButton,
  );
  const renderGoBackButton = chooseFirstDefinedValue(
    showGoBackButton,
    urlParams.showGoBackButton,
    DEFAULT_PROPS.showGoBackButton,
  );

  const redirectToLogin = useCallback(() => {
    history.push(ROUTES.LOGIN);
  }, [history]);

  const redirectToRoot = useCallback(() => {
    history.push(ROUTES.LOGIN);
  }, [history]);

  const homeButton = useMemo(
    () =>
      isAuthenticated ? (
        <Button onClick={redirectToRoot} type='primary'>
          Strona główna
        </Button>
      ) : (
        <Button onClick={redirectToLogin} type='primary'>
          Wróc do logowania
        </Button>
      ),
    [isAuthenticated, redirectToLogin, redirectToRoot],
  );

  return (
    <>
      <Helmet>
        <title>{pageTitleRender}</title>
      </Helmet>

      <div className={styles.root} style={{ paddingTop: 0 }}>
        <Card title={title} style={{ width: 400 }}>
          <p>{description}</p>
          <Space
            direction='horizontal'
            align='center'
            style={{ width: '100%' }}
          >
            {handleReportErrorClick ? (
              <Button
                onClick={handleReportErrorClick}
                block
                style={{ flex: 1 }}
              >
                Zgłoś bład
              </Button>
            ) : null}

            {renderGoBackButton && (
              <Button
                onClick={handleGoBack}
                block
                disabled={!lastLocation && reloadOnGoBack}
              >
                Wstecz
              </Button>
            )}

            {renderRetryButton && (
              <Button onClick={onRetryClick || handleRetry} block>
                Spróbuj ponownie
              </Button>
            )}

            {renderHomeButton && homeButton}
          </Space>
        </Card>
      </div>
    </>
  );
};
