/* eslint-disable prefer-const */
import queryString from 'query-string';
import { isArray } from 'lodash';

import { ErrorPageParams } from './index';

export const locationParse = (search: string): ErrorPageParams => {
  let {
    error,
    errorDescription,
    code,
    showRetryButton,
    showHomeButton,
    showGoBackButton,
    pageTitle,
  } = queryString.parse(search);
  let codeNumber: number | undefined;

  if (isArray(error)) {
    error = error[0];
  }

  if (isArray(errorDescription)) {
    errorDescription = errorDescription[0];
  }

  if (isArray(code) && code[0]) {
    codeNumber = +code[0];
  }

  if (isArray(pageTitle)) {
    pageTitle = pageTitle[0];
  }

  return {
    error: error || undefined,
    errorDescription: errorDescription || undefined,
    code: codeNumber || undefined,
    showRetryButton: !!showRetryButton,
    showHomeButton: !!showHomeButton,
    showGoBackButton: !!showGoBackButton,
    pageTitle: pageTitle || undefined,
  };
};

export const parseErrorWithCode = ({
  error,
  code,
}: {
  error?: string;
  code?: number;
}) => {
  const messageChunks = [];

  if (code) {
    messageChunks.push(`Error: ${code}`);
  }

  if (error) {
    messageChunks.push(error);
  }

  return messageChunks.join(' ');
};

export const chooseFirstDefinedValue = (
  ...values: Array<boolean | undefined>
) => values.find((value) => value !== undefined);
