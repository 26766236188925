import RequestService from './Request';
import qs from 'query-string';

import {
  ActivityDTO,
  CreateUserDTO,
  UpdateUserDTO,
  UpdateUserPasswordDTO,
  UserDTO,
  UserStatsDTO,
} from '~/dtos';

class _UserService {
  getMe(): Promise<UserDTO> {
    return RequestService.client
      .get<UserDTO>('/users/me')
      .then((response) => response.data);
  }

  getUsers(): Promise<UserDTO[]> {
    return RequestService.client
      .get<UserDTO[]>('/users')
      .then((response) => response.data);
  }

  getReport(startDate: string, endDate: string): Promise<UserStatsDTO[]> {
    const params = qs.stringify({ startDate, endDate });
    return RequestService.client
      .get<UserStatsDTO[]>(`/users/report?${params}`)
      .then((response) => response.data);
  }

  getActivity(startDate: string, endDate: string): Promise<ActivityDTO[]> {
    const params = qs.stringify({ startDate, endDate });
    return RequestService.client
      .get<ActivityDTO[]>(`/users/activity?${params}`)
      .then((response) => response.data);
  }

  createUser(data: CreateUserDTO): Promise<UserDTO> {
    return RequestService.client
      .post<UserDTO>('/users', data)
      .then((response) => response.data);
  }

  removeUser(id: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/users/${id}`)
      .then(() => Promise.resolve());
  }

  updateUser(id: string, data: UpdateUserDTO): Promise<UserDTO> {
    return RequestService.client
      .patch<UserDTO>(`/users/${id}`, data)
      .then((response) => response.data);
  }

  updateUserPassword(id: string, data: UpdateUserPasswordDTO): Promise<void> {
    return RequestService.client
      .patch<void>(`/users/${id}/password`, data)
      .then(() => Promise.resolve());
  }
}

const UserService = new _UserService();

export { UserService, _UserService };
