import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextArea from 'antd/es/input/TextArea';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, Form, Select, Typography } from 'antd';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import {
  useCreateEntryObservation,
  useUpdateEntryObservation,
} from '~/queries/useVisit';
import {
  CreateEntryObservationDTO,
  EntryObservationDTO,
  EntryObservationType,
  PatientDTO,
  VisitDTO,
} from '~/dtos';
import { TextAreaProps } from 'antd/lib/input';
import { startCase } from 'lodash';
import { GoBackButton } from '~/components/GoBackButton';

const schema = yup.object().shape({
  reason: yup.string().required('To pole jest wymagane.'),
  type: yup.string().required('To pole jest wymagane.'),
  disease: yup.string().required('To pole jest wymagane.'),
  afflictions: yup.string().required('To pole jest wymagane.'),
  hospitalized: yup.string().required('To pole jest wymagane.'),
  drugs: yup.string().required('To pole jest wymagane.'),
  state: yup.string().required('To pole jest wymagane.'),
});

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const EntryObservationForm = ({ visit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation<{ entryObservation?: EntryObservationDTO }>();
  const isEditing = Boolean(location.state?.entryObservation);
  const { handleSubmit, control, formState, setValue } =
    useForm<CreateEntryObservationDTO>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    if (location.state?.entryObservation) {
      const { entryObservation } = location.state;
      setValue('reason', entryObservation.reason);
      setValue('type', entryObservation.type);
      setValue('disease', entryObservation.disease);
      setValue('afflictions', entryObservation.afflictions);
      setValue('hospitalized', entryObservation.hospitalized);
      setValue('drugs', entryObservation.drugs);
      setValue('state', entryObservation.state);
    }
  }, [location.state, setValue]);

  const { mutate: createEntryObservation, isLoading } =
    useCreateEntryObservation(visit.id, () => {
      history.push(
        createPatientVisitRoute(
          ROUTES.dashboard.patient.visit.ENTRY_OBSERVATIONS,
          patient.id,
          visit.id,
        ),
      );
    });

  const { mutate: updateEntryObservation } = useUpdateEntryObservation(
    visit.id,
    location.state?.entryObservation?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data) => {
      if (isEditing) {
        updateEntryObservation(data);
      } else {
        createEntryObservation(data);
      }
    },
    [createEntryObservation, isEditing, updateEntryObservation],
  );

  const renderTextArea = useCallback(
    (
      label: string,
      name: keyof CreateEntryObservationDTO,
      props?: TextAreaProps,
    ) => {
      const errors = formState.errors[name];
      return (
        <Form.Item
          label={label}
          required
          validateStatus={formState.errors[name] ? 'error' : 'validating'}
          help={errors ? errors.message : undefined}
        >
          <Controller
            name={name}
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea
                autoSize
                {...field}
                value={field.value || ''}
                {...props}
              />
            )}
          />
        </Form.Item>
      );
    },
    [formState, control],
  );

  const { type } = formState.errors;

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawanie'
          } wizyty przyjęciowej`}
        />
      }
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Typography.Paragraph>
          Jeśli któreś pole nie dotyczy danego pacjenta, wpisz &quot;Nie
          dotyczy&quot;.
        </Typography.Paragraph>
        <Form.Item
          label='Typ wizyty'
          required
          validateStatus={type ? 'error' : 'validating'}
          help={type ? type.message : undefined}
        >
          <Controller
            name='type'
            control={control}
            defaultValue={EntryObservationType.INTERN}
            render={({ field }) => (
              <Select<string>
                key='select'
                onSelect={field.onChange}
                placeholder='Wybierz typ wizyty'
                value={field.value}
              >
                {Object.values(EntryObservationType).map((type, idx) => (
                  <Select.Option value={type} key={idx}>
                    {startCase(type)}
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>
        {renderTextArea('Przyczyna przyjęcia', 'reason')}
        {renderTextArea(
          'Dotychczasowy przebieg podstawowej choroby',
          'disease',
        )}
        {renderTextArea('Dolegliwości z pozostałych układów', 'afflictions')}
        {renderTextArea('Dotychczasowe hospitalizacje', 'hospitalized')}
        {renderTextArea('Używki/Sytuacja rodzinna/Sytuacja zawodowa', 'drugs')}
        {renderTextArea(
          'Badanie przedmiotowe/Rozpoznanie wstępne/Zalecenia',
          'state',
        )}

        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          {isEditing ? 'Edytuj' : 'Dodaj'} wizytę przyjęciową
        </Button>
      </Form>
    </Card>
  );
};
