import RequestService from './Request';

import { UpdateEntryObservationDTO, EntryObservationDTO } from '~/dtos';

class _EntryObservationService {
  getEntryObservation(id: string): Promise<EntryObservationDTO> {
    return RequestService.client
      .get<EntryObservationDTO>(`/entry-observations/${id}`)
      .then((response) => response.data);
  }

  removeEntryObservation(id: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/entry-observations/${id}`)
      .then(() => Promise.resolve());
  }

  updateEntryObservation(
    id: string,
    data: UpdateEntryObservationDTO,
  ): Promise<EntryObservationDTO> {
    return RequestService.client
      .patch<EntryObservationDTO>(`/entry-observations/${id}`, data)
      .then((response) => response.data);
  }
}

const EntryObservationService = new _EntryObservationService();

export { EntryObservationService, _EntryObservationService };
