import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import { isString } from 'lodash';

const { Option } = Select;

export interface Props {
  className?: string;
  value?: PATIENTS_FILTER;
  onSelect?: (filter: PATIENTS_FILTER) => void;
  style?: CSSProperties;
}

export enum PATIENTS_FILTER {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  MY_PATIENTS = 'MY_PATIENTS',
}

export const FILTER_TO_HUMAN: { [key in PATIENTS_FILTER]: string } = {
  [PATIENTS_FILTER.MY_PATIENTS]: 'Moi pacjenci',
  [PATIENTS_FILTER.ACTIVE]: 'Pacjenci obecji',
  [PATIENTS_FILTER.ARCHIVED]: 'Pacjenci archiwum',
};

export const PatientsFilterSelect: React.FC<Props> = (props) => {
  return (
    <Select<PATIENTS_FILTER>
      showSearch
      value={props.value}
      style={{ width: 220, ...props.style }}
      placeholder='Pacjenci'
      optionFilterProp='children'
      onChange={props.onSelect}
      filterOption={(input, option) => {
        if (option && option.children && input) {
          const parsedName = isString(option.children)
            ? option.children
            : option.children[0];

          return parsedName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }

        return false;
      }}
    >
      {Object.values(PATIENTS_FILTER).map((v) => (
        <Option value={v} key={v}>
          {FILTER_TO_HUMAN[v]}
        </Option>
      ))}
    </Select>
  );
};
