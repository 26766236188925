import { isObject } from 'lodash';

interface InitialRoute {
  ROOT: string;
}

function withParentPrefix<M>(prefix: string, routes: M): M & InitialRoute {
  const initial: M & InitialRoute = Object.assign(
    {
      ROOT: prefix,
    },
    routes,
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.keys(routes).reduce(
    (accumulator: M & InitialRoute, key: string) =>
      Object.assign(accumulator, {
        [key]: isObject(routes[key])
          ? withParentPrefix(prefix, routes[key])
          : `${prefix}${routes[key]}`,
      }),
    initial,
  );
}

export { withParentPrefix };
