import RequestService from './Request';
import { PsychoObservationDTO, UpdatePsychoObservationDTO } from '~/dtos';

class _PsychoObservationService {
  updatePsychoObservation(
    id: string,
    data: UpdatePsychoObservationDTO,
  ): Promise<PsychoObservationDTO> {
    return RequestService.client
      .patch<PsychoObservationDTO>(`/psycho-observations/${id}`, data)
      .then((response) => response.data);
  }

  getPsychoObservation(id: string): Promise<PsychoObservationDTO> {
    return RequestService.client
      .get<PsychoObservationDTO>(`/psycho-observations/${id}`)
      .then((response) => response.data);
  }
}

const PsychoObservationService = new _PsychoObservationService();

export { PsychoObservationService };
