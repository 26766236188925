import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Input, InputProps, Modal } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DrugDTO } from '~/dtos';
import { useCreateVisitDrug, useUpdateVisitDrug } from '~/queries/useVisit';

const schema = yup.object().shape({
  name: yup.string().required('To pole jest wymagane'),
  morning: yup.string().required('To pole jest wymagane'),
  noon: yup.string().required('To pole jest wymagane'),
  evening: yup.string().required('To pole jest wymagane'),
  night: yup.string().required('To pole jest wymagane'),
});

interface Props {
  visitId: string;
  isModalVisible: boolean;
  item: DrugDTO | null;
  onClose: () => void;
}

export const DrugsFormModal = ({
  visitId,
  onClose,
  item,
  isModalVisible,
}: Props) => {
  const { control, formState, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    reset();
    onClose();
  };

  const { mutate: createDrug, isLoading } = useCreateVisitDrug(
    visitId,
    onSuccess,
  );

  useEffect(() => {
    if (item) {
      setValue('name', item.name);
      setValue('morning', item.morning);
      setValue('noon', item.noon);
      setValue('evening', item.evening);
      setValue('night', item.night);
    }
  }, [item, setValue]);

  const { mutate: updateDrug } = useUpdateVisitDrug(
    visitId,
    item?.id || '',
    onSuccess,
  );

  const onSubmit = useCallback(
    (data) => {
      if (item) {
        updateDrug(data);
      } else {
        createDrug(data);
      }
    },
    [createDrug, updateDrug, item],
  );

  const renderInput = useCallback(
    (label: string, name: string, props?: InputProps) => (
      <Form.Item
        label={label}
        required
        validateStatus={formState.errors[name] ? 'error' : 'validating'}
        help={
          formState.errors[name] ? formState.errors[name]?.message : undefined
        }
      >
        <Controller
          name={name}
          control={control}
          defaultValue=''
          render={({ field }) => <Input {...field} {...props} />}
        />
      </Form.Item>
    ),
    [formState, control],
  );

  return (
    <Modal
      title={item ? 'Edytuj lek' : 'Dodaj lek'}
      visible={isModalVisible}
      onOk={handleSubmit(onSubmit)}
      cancelText='Anuluj'
      okText={item ? 'Edytuj lek' : 'Dodaj lek'}
      onCancel={() => onClose()}
      confirmLoading={isLoading}
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        {renderInput('Nazwa leku', 'name')}
        {renderInput('Rano', 'morning')}
        {renderInput('Południe', 'noon')}
        {renderInput('Wieczór', 'evening')}
        {renderInput('Noc', 'night')}
      </Form>
    </Modal>
  );
};
