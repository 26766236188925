import { action, computed, thunkOn } from 'easy-peasy';

import { Action, Computed, ThunkOn } from 'easy-peasy';

import { Role, UserDTO } from '~/dtos';
import { StoreModel, StoreInjections } from '../types';

export interface UserModel {
  data?: UserDTO;
  isAdmin: Computed<UserModel, boolean, StoreModel>;
  setUserData: Action<UserModel, UserDTO>;
  OnAuthentication: ThunkOn<UserModel, StoreInjections, StoreModel>;
}

const userState: UserModel = {
  data: undefined,
  isAdmin: computed((store) => {
    return store.data ? store.data.role === Role.ADMIN : false;
  }),

  setUserData: action((state, payload) => {
    state.data = {
      ...payload,
    };
  }),

  OnAuthentication: thunkOn(
    (actions, storeActions) => storeActions.auth.setAsAuthenticated,
    async (actions, target, helpers) => {
      const { userService } = helpers.injections;
      const result = await userService.getMe();
      actions.setUserData(result);

      return result;
    },
  ),
};

export default userState;
