import { createStore } from 'easy-peasy';

import { UserService } from '~/services/User';
import { AuthService } from '~/services/Auth';
import { history } from '~/utils/history';

import auth from './auth';
import user from './user';

import { StoreModel, StoreInjections } from './types';

export const injections: StoreInjections = {
  userService: UserService,
  authService: AuthService,
  history,
};

export const storeModel: StoreModel = {
  auth,
  user,
};

const initStore = () => createStore(storeModel, { injections });

export default initStore;
