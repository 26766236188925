import React, { useMemo } from 'react';
import {
  useDownloadExaminationDocument,
  useDownloadVisitDocument,
} from '~/queries/useDocument';
import { Button, ButtonProps } from 'antd';
import { useDownloadVisitFile } from '~/queries/useVisit';

export interface Props extends ButtonProps {
  documentId: string;
  version: 'examination' | 'visit' | 'visit-file';
}

export const DownloadButton: React.FC<Props> = ({
  documentId,
  version,
  children,
  ...restProps
}) => {
  const fn = useMemo(() => {
    let selectedFn = useDownloadExaminationDocument;

    if (version === 'visit') {
      selectedFn = useDownloadVisitDocument;
    }

    if (version === 'visit-file') {
      selectedFn = useDownloadVisitFile;
    }

    return selectedFn;
  }, [version]);

  const { isLoading, mutate } = fn(documentId, (data) => {
    window.open(data.downloadUrl, '_blank');
  });

  return (
    <Button loading={isLoading} onClick={() => mutate()} {...restProps}>
      {children}
    </Button>
  );
};
