import React, { useCallback } from 'react';
import { DrugDTO, Status, VisitDTO } from '~/dtos';
import { Button, Space } from 'antd';
import { useRemoveVisitDrug } from '~/queries/useVisit';

interface Props {
  drug: DrugDTO;
  visit: VisitDTO;
  onSelectItem: (drug: DrugDTO) => void;
}

export const DrugActions = ({ visit, drug, onSelectItem }: Props) => {
  const { mutate: removeDrug, isLoading } = useRemoveVisitDrug(visit.id);

  const handleClick = useCallback(() => {
    removeDrug(drug.id);
  }, [drug, removeDrug]);

  return (
    <Space>
      <Button
        onClick={handleClick}
        loading={isLoading}
        danger
        disabled={visit.status !== Status.ACTIVE}
      >
        Usuń
      </Button>
      <Button
        onClick={() => onSelectItem(drug)}
        disabled={visit.status !== Status.ACTIVE}
      >
        Edytuj
      </Button>
    </Space>
  );
};
