import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ROUTES } from '~/constants';

import { UsersView } from './users';
import { UserProfileView } from './user-profile';
import { PatientsView } from './patients';
import { PatientView } from './patient';
import { NewVisitForPatientView } from './new-visit';
import { PatientFormView } from './patient/views/patient-form';
import { VisitForm } from './patient/views/stay-form';
import { ReportsView } from './reports';
import { ActivityView } from '~/views/dashboard/actvity';

const {
  PATIENTS,
  ROOT,
  USERS,
  USER_PROFILE,
  patient,
  ACTIVITY,
  NEW_VISIT,
  PATIENT_FORM,
  VISIT_FORM,
  REPORTS,
} = ROUTES.dashboard;

const DashboardView: React.FC = () => {
  return (
    <Switch>
      <Route path={ROOT} exact>
        <Redirect to={PATIENTS} />
      </Route>
      <Route path={USERS} exact>
        <UsersView />
      </Route>
      <Route path={NEW_VISIT} exact>
        <NewVisitForPatientView />
      </Route>
      <Route path={PATIENT_FORM} exact>
        <PatientFormView />
      </Route>
      <Route path={REPORTS} exact>
        <ReportsView />
      </Route>
      <Route path={ACTIVITY} exact>
        <ActivityView />
      </Route>
      <Route path={PATIENTS} exact>
        <PatientsView />
      </Route>
      <Route path={patient.visit.ROOT}>
        <PatientView />
      </Route>
      <Route path={patient.ROOT}>
        <PatientView />
      </Route>
      <Route path={USER_PROFILE} exact>
        <UserProfileView />
      </Route>
      <Route path={VISIT_FORM} exact>
        <VisitForm />
      </Route>
    </Switch>
  );
};

export default DashboardView;
