import React from 'react';
import { Button, Collapse, Empty, Row, Skeleton, Typography } from 'antd';
import { PatientDTO, Status, VisitDTO } from '~/dtos';
import { useVisitEntryObservations } from '~/queries/useVisit';
import {
  createPatientVisitRoute,
  fullDateWithMonthFormat,
  ROUTES,
} from '~/constants';
import { Link, useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { EditOutlined } from '@ant-design/icons';

export interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const EntryObservationsView = ({ visit, patient }: Props) => {
  const history = useHistory();
  const { data, isLoading } = useVisitEntryObservations(visit.id);
  const formUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.ENTRY_OBSERVATION_FORM,
    patient.id,
    visit.id,
  );

  return (
    <div style={{ padding: 16 }}>
      <Row justify='space-between' style={{ width: '100%' }}>
        <Typography.Title level={2}>Wizyty przyjęciowe</Typography.Title>
        <Link to={formUrl}>
          <Button
            type='primary'
            style={{ width: 300 }}
            disabled={visit.status !== Status.ACTIVE}
          >
            Dodaj wizytę przyjęciową
          </Button>
        </Link>
      </Row>
      <Skeleton loading={isLoading}>
        {data?.length === 0 && <Empty description='Brak wizyt do tej pory' />}
        {data && data.length > 0 ? (
          <Collapse accordion>
            {data?.map((el) => (
              <Collapse.Panel
                extra={
                  <Button
                    size='small'
                    disabled={visit.status !== Status.ACTIVE}
                    onClick={() =>
                      history.push(formUrl, {
                        entryObservation: el,
                      })
                    }
                  >
                    <EditOutlined />
                  </Button>
                }
                key={el.id}
                header={`${startCase(el.type)} – ${
                  el.addedBy.name
                } – dodano ${fullDateWithMonthFormat(el.createdAt)}`}
              >
                <Typography.Title level={5}>
                  Przyczyna przyjęcia:
                </Typography.Title>

                <Typography.Paragraph>{el.reason}</Typography.Paragraph>

                <Typography.Title level={5}>
                  Dotychczasowy przebieg podstawowej choroby:
                </Typography.Title>
                <Typography.Paragraph>{el.disease}</Typography.Paragraph>

                <Typography.Title level={5}>
                  Dolegliwości z pozostałych układów:
                </Typography.Title>
                <Typography.Paragraph>{el.afflictions}</Typography.Paragraph>

                <Typography.Title level={5}>
                  Dotychczasowa hospitalizacja:
                </Typography.Title>
                <Typography.Paragraph>{el.hospitalized}</Typography.Paragraph>

                <Typography.Title level={5}>
                  Używki/Sytuacja rodzinna/Sytuacja zawodowa:
                </Typography.Title>
                <Typography.Paragraph>{el.drugs}</Typography.Paragraph>

                <Typography.Title level={5}>
                  Badanie przedmiotowe/Rozpoznanie wstępne/Zalecenia
                </Typography.Title>
                <Typography.Paragraph>{el.state}</Typography.Paragraph>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : null}
      </Skeleton>
    </div>
  );
};
