import RequestService from './Request';

import { UpdateDrugDTO, DrugDTO } from '~/dtos';

class _DrugService {
  getDrug(id: string): Promise<DrugDTO> {
    return RequestService.client
      .get<DrugDTO>(`/drugs/${id}`)
      .then((response) => response.data);
  }

  removeDrug(id: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/drugs/${id}`)
      .then(() => Promise.resolve());
  }

  updateDrug(id: string, data: UpdateDrugDTO): Promise<DrugDTO> {
    return RequestService.client
      .patch<DrugDTO>(`/drugs/${id}`, data)
      .then((response) => response.data);
  }
}

const DrugService = new _DrugService();

export { DrugService, _DrugService };
