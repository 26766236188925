import { useEffect } from 'react';

export function useOnSearchShortCut(onShortcut: () => void): void {
  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if ((e.metaKey && e.key === 'f') || (e.ctrlKey && e.key === 'f')) {
        e.preventDefault();
        onShortcut();
      }
    };

    window.addEventListener('keydown', eventHandler);

    return () => {
      window.removeEventListener('keydown', eventHandler);
    };
  }, [onShortcut]);
}
