import { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { Avatar, Table } from 'antd';
import { ROLE_TO_HUMAN, UserDTO } from '~/dtos';
import { UserOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';

import { useStoreState } from '~/global-state/hooks';
import { useUsers } from '~/queries';
import dateUtils from '~/utils/date';

import { AddUserForm } from './AddUserForm';
import { Actions } from './Actions';

interface ExtendedUser extends UserDTO {
  isCurrentUser: boolean;
}

const columns: ColumnsType<ExtendedUser> = [
  {
    title: 'Imię i nazwisko',
    dataIndex: 'name',
    render: (_, user) => (
      <div>
        <Avatar
          icon={<UserOutlined />}
          src={user.avatarUrl}
          style={{ marginRight: 8 }}
        />
        {user.name}
      </div>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Rola',
    dataIndex: 'role',
    align: 'center',
    render: (_, user) => {
      const parsed = ROLE_TO_HUMAN[user.role];

      if (!parsed) {
        return '-';
      }

      return parsed;
    },
  },
  {
    title: 'Ostatnia aktualizacja',
    dataIndex: 'updatedAt',
    align: 'center',
    render: (_, user) => dateUtils.fromNow(user.createdAt),
  },
  {
    title: 'Akcje',
    dataIndex: '',
    align: 'center',
    render: (_, user) => {
      if (!user.isCurrentUser) {
        return 'To ty';
      }

      return <Actions user={user} />;
    },
  },
];

export const UsersView: React.FC = () => {
  const currentUser = useStoreState((s) => s.user.data);
  const { isLoading, data } = useUsers();

  const users: ExtendedUser[] = useMemo(
    () =>
      sortBy(
        (data || []).map((u) => ({
          ...u,
          isCurrentUser: currentUser?.id !== u.id,
        })),
        'name',
      ),
    [data, currentUser],
  );

  return (
    <div>
      <Table
        rowKey='id'
        bordered
        pagination={false}
        columns={columns}
        dataSource={users}
        loading={isLoading}
      />
      <AddUserForm />
    </div>
  );
};
