import { Button, Card, DatePicker, Form, Skeleton } from 'antd';
import * as yup from 'yup';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { usePatient } from '~/queries/usePatients';
import { DepartmentSelect } from '~/components/DepartmentSelect';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCreateVisit } from '~/queries/useVisit';
import { createPatientVisitRoute, ROUTES } from '~/constants';

const schema = yup.object().shape({
  department: yup.string().required('Placówka jest wymagana'),
  startedAt: yup.string().required('Data przyjścia do placówki jest wymagana'),
});

export const NewVisitForPatientView: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: patient, isLoading: isLoadingPatient } = usePatient(
    match.params.id,
  );
  const { mutate, isLoading } = useCreateVisit(match.params.id, (visit) => {
    const url = createPatientVisitRoute(
      ROUTES.dashboard.patient.visit.ROOT,
      match.params.id,
      visit.id,
    );

    history.push(url);
  });

  const onSubmit = useCallback(
    (data) => {
      mutate(data);
    },
    [mutate],
  );

  const { department, startedAt } = formState.errors;

  return (
    <Skeleton loading={isLoadingPatient} active>
      <Card
        title={
          'Formularz nowej wizyty' +
          (patient ? ` dla "${patient?.firstName} ${patient?.lastName}"` : '')
        }
      >
        <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
          <Form.Item
            label='Placówka'
            required
            validateStatus={department ? 'error' : 'validating'}
            help={department ? department.message : undefined}
          >
            <Controller
              name='department'
              control={control}
              render={({ field }) => (
                <DepartmentSelect
                  onSelect={field.onChange}
                  selectedDepartment={field.value}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Form.Item>

          <Form.Item
            label='Data przyjścia do placówki'
            required
            validateStatus={startedAt ? 'error' : 'validating'}
            help={startedAt ? startedAt.message : undefined}
          >
            <Controller
              name='startedAt'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <DatePicker
                  placeholder='Wybierz date rozpoczęcia wizyty'
                  onChange={field.onChange}
                  value={field.value}
                  style={{ width: '100%' }}
                />
              )}
            />
          </Form.Item>

          <Button
            block
            type='primary'
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          >
            Rozpocznij nowa wizyte
          </Button>
        </Form>
      </Card>
    </Skeleton>
  );
};
