import RequestService from './Request';

import {
  ConfirmVisitExitDTO,
  CreateDrugDTO,
  CreateEntryObservationDTO,
  CreateEpicrisisDTO,
  CreateExaminationDTO,
  CreateObservationDTO,
  CreateVisitDTO,
  DrugDTO,
  EntryObservationDTO,
  EpicrisisDTO,
  ExaminationDTO,
  GenerateExcerptDocumentDTO,
  GenerateExcerptDocumentResponseDTO,
  ObservationDTO,
  UpdateVisitDTO,
  VisitDTO,
  ChartsResponseDTO,
  PsychoObservationDTO,
  CreatePsychoObservationDTO,
  UpdateExaminationDTO,
  UpdateEpicrisisDTO,
  CancelVisitDTO,
  CreateVisitFileDTO,
  VisitFileDTO,
  FileDownloadDTO,
  NurseObservationDTO,
  CreateNurseObservationDTO,
} from '~/dtos';

class _VisitService {
  getVisit(visitId: string): Promise<VisitDTO> {
    return RequestService.client
      .get<VisitDTO>(`/visits/${visitId}`)
      .then((response) => response.data);
  }

  createVisit(patientId: string, data: CreateVisitDTO): Promise<VisitDTO> {
    return RequestService.client
      .post<VisitDTO>(`/patients/${patientId}/visit`, data)
      .then((response) => response.data);
  }

  updateVisit(visitId: string, data: UpdateVisitDTO): Promise<VisitDTO> {
    return RequestService.client
      .patch<VisitDTO>(`/visits/${visitId}`, data)
      .then((response) => response.data);
  }

  getVisitObservations(visitId: string): Promise<ObservationDTO[]> {
    return RequestService.client
      .get<ObservationDTO[]>(`/visits/${visitId}/observations`)
      .then((response) => response.data);
  }

  createVisitObservation(
    visitId: string,
    data: CreateObservationDTO,
  ): Promise<ObservationDTO> {
    return RequestService.client
      .post<ObservationDTO>(`/visits/${visitId}/observations`, data)
      .then((response) => response.data);
  }

  getVisitEntryObservations(visitId: string): Promise<EntryObservationDTO[]> {
    return RequestService.client
      .get<EntryObservationDTO[]>(`/visits/${visitId}/entry-observations`)
      .then((response) => response.data);
  }

  createVisitFile(
    visitId: string,
    data: CreateVisitFileDTO,
  ): Promise<VisitFileDTO> {
    return RequestService.client
      .post<VisitFileDTO>(`/visits/${visitId}/files`, data)
      .then((response) => response.data);
  }

  getVisitFiles(visitId: string): Promise<VisitFileDTO[]> {
    return RequestService.client
      .get<VisitFileDTO[]>(`/visits/${visitId}/files`)
      .then((response) => response.data);
  }

  downloadFileVisitDocument(fileId: string): Promise<FileDownloadDTO> {
    return RequestService.client
      .get<FileDownloadDTO>(`/visits/files/download/${fileId}`)
      .then((response) => response.data);
  }

  deleteFileVisitDocument(fileId: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/visits/files/${fileId}`)
      .then((response) => response.data);
  }

  createVisitEntryObservation(
    visitId: string,
    data: CreateEntryObservationDTO,
  ): Promise<EntryObservationDTO> {
    return RequestService.client
      .post<EntryObservationDTO>(`/visits/${visitId}/entry-observations`, data)
      .then((response) => response.data);
  }

  getVisitDrugs(visitId: string): Promise<DrugDTO[]> {
    return RequestService.client
      .get<DrugDTO[]>(`/visits/${visitId}/drugs`)
      .then((response) => response.data);
  }

  createVisitDrug(visitId: string, data: CreateDrugDTO): Promise<DrugDTO> {
    return RequestService.client
      .post<DrugDTO>(`/visits/${visitId}/drugs`, data)
      .then((response) => response.data);
  }

  removeVisitDrug(visitId: string, drugId: string): Promise<DrugDTO> {
    return RequestService.client
      .delete<DrugDTO>(`/visits/${visitId}/drugs/${drugId}`)
      .then((response) => response.data);
  }

  getVisitEpicrisises(visitId: string): Promise<EpicrisisDTO[]> {
    return RequestService.client
      .get<EpicrisisDTO[]>(`/visits/${visitId}/epicrisises`)
      .then((response) => response.data);
  }

  createVisitEpicrisis(
    visitId: string,
    data: CreateEpicrisisDTO,
  ): Promise<EpicrisisDTO> {
    return RequestService.client
      .post<EpicrisisDTO>(`/visits/${visitId}/epicrisises`, data)
      .then((response) => response.data);
  }

  updateVisitEpicrisis(
    epicrisisesId: string,
    data: UpdateEpicrisisDTO,
  ): Promise<EpicrisisDTO> {
    return RequestService.client
      .patch<EpicrisisDTO>(`/epicrisises/${epicrisisesId}`, data)
      .then((response) => response.data);
  }

  getVisitExaminations(visitId: string): Promise<ExaminationDTO[]> {
    return RequestService.client
      .get<ExaminationDTO[]>(`/visits/${visitId}/examinations`)
      .then((response) => response.data);
  }

  createVisitExamination(
    visitId: string,
    data: CreateExaminationDTO,
  ): Promise<ExaminationDTO> {
    return RequestService.client
      .post<ExaminationDTO>(`/visits/${visitId}/examinations`, data)
      .then((response) => response.data);
  }

  updateVisitExamination(
    examinationsId: string,
    data: UpdateExaminationDTO,
  ): Promise<ExaminationDTO> {
    return RequestService.client
      .patch<ExaminationDTO>(`/examinations/${examinationsId}`, data)
      .then((response) => response.data);
  }

  generateExcerptDocument(
    visitId: string,
    data: GenerateExcerptDocumentDTO,
  ): Promise<GenerateExcerptDocumentResponseDTO> {
    return RequestService.client
      .post<GenerateExcerptDocumentResponseDTO>(
        `/visits/${visitId}/generate-exit`,
        data,
      )
      .then((response) => response.data);
  }

  cancelVisit(visitId: string, data: CancelVisitDTO): Promise<VisitDTO> {
    return RequestService.client
      .post<VisitDTO>(`/visits/${visitId}/cancel`, data)
      .then((response) => response.data);
  }

  confirmExit(visitId: string, data: ConfirmVisitExitDTO): Promise<VisitDTO> {
    return RequestService.client
      .post<VisitDTO>(`/visits/${visitId}/confirm-exit`, data)
      .then((response) => response.data);
  }

  chartsData(visitId: string): Promise<ChartsResponseDTO> {
    return RequestService.client
      .get<ChartsResponseDTO>(`/visits/${visitId}/charts`)
      .then((response) => response.data);
  }

  visitPsychoObservations(visitId: string): Promise<PsychoObservationDTO[]> {
    return RequestService.client
      .get<PsychoObservationDTO[]>(`/visits/${visitId}/psycho-observations`)
      .then((response) => response.data);
  }

  createPsychoObservation(
    visitId: string,
    data: CreatePsychoObservationDTO,
  ): Promise<PsychoObservationDTO> {
    return RequestService.client
      .post<PsychoObservationDTO>(
        `/visits/${visitId}/psycho-observations`,
        data,
      )
      .then((response) => response.data);
  }

  visitNurseObservations(visitId: string): Promise<NurseObservationDTO[]> {
    return RequestService.client
      .get<NurseObservationDTO[]>(`/visits/${visitId}/nurse-observations`)
      .then((response) => response.data);
  }

  createNurseObservation(
    visitId: string,
    data: CreateNurseObservationDTO,
  ): Promise<NurseObservationDTO> {
    return RequestService.client
      .post<NurseObservationDTO>(`/visits/${visitId}/nurse-observations`, data)
      .then((response) => response.data);
  }
}

const VisitService = new _VisitService();

export { VisitService, _VisitService };
