import React from 'react';
import { ExaminationDTO, PatientDTO, Status, VisitDTO } from '~/dtos';
import { Button, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
// import { EditOutlined } from '@ant-design/icons';

import { useVisitExaminations } from '~/queries/useVisit';
import {
  createPatientVisitRoute,
  fullDateWithMonthFormat,
  ROUTES,
} from '~/constants';
import { DownloadButton } from '~/views/dashboard/patient/views/examinations/DownloadButton';

const generateColumns = (): // editUrl: (res: ExaminationDTO) => void,
ColumnsType<ExaminationDTO> => [
  {
    title: 'Nazwa badania',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    align: 'center',
  },
  {
    title: 'Załączone pliki',
    align: 'center',
    width: 400,
    render: (_, examination) => {
      return (
        <Space direction='vertical'>
          {examination.documents.map((d) => (
            <DownloadButton document={d} key={d.id} />
          ))}
        </Space>
      );
    },
  },
  {
    title: 'Data wykonania',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 300,
    render: (_, examination) =>
      `${fullDateWithMonthFormat(examination.createdAt)} (${dayjs(
        examination.createdAt,
      ).fromNow()})`,
  },
  {
    title: 'Notatka',
    dataIndex: 'note',
    key: 'note',
    render: (_, examination) => examination.note,
  },
  {
    title: 'Dodane przez',
    dataIndex: 'addedBy',
    key: 'addedBy',
    align: 'center',
    width: 300,
    render: (_, examination) => examination.addedBy.name,
  },
  // {
  //   title: 'Akcje',
  //   dataIndex: 'action',
  //   key: 'action',
  //   align: 'center',
  //   width: 100,
  //   render: (_, examination) => (
  //     <Button onClick={() => editUrl(examination)}>
  //       <EditOutlined />
  //     </Button>
  //   ),
  // },
];

export interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const ExaminationsView = ({ visit, patient }: Props) => {
  const { data, isLoading } = useVisitExaminations(visit.id);
  // const history = useHistory();
  const formUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.EXAMINATIONS_FORM,
    patient.id,
    visit.id,
  );

  // const editUrl = (examination: ExaminationDTO) =>
  //   history.push(formUrl, { examination });

  return (
    <div style={{ padding: 16 }}>
      <Row justify='space-between' style={{ width: '100%' }}>
        <Typography.Title level={2}>Badania</Typography.Title>
        <Link to={formUrl}>
          <Button
            type='primary'
            style={{ width: 300 }}
            disabled={visit.status !== Status.ACTIVE}
          >
            Dodaj nowe badanie
          </Button>
        </Link>
      </Row>
      <Table<ExaminationDTO>
        rowKey='id'
        columns={generateColumns()}
        loading={isLoading}
        dataSource={data}
        bordered
      />
    </div>
  );
};
