import React from 'react';
import { Select, SelectProps } from 'antd';
import { isString } from 'lodash';
import { useUsers } from '~/queries';
import { ROLE_TO_HUMAN } from '~/dtos';

const { Option } = Select;

export const UserSelect = (props: SelectProps<string>) => {
  const { data, isLoading } = useUsers();

  return (
    <Select
      loading={isLoading}
      showSearch
      placeholder='Wybierz lekarza'
      optionFilterProp='children'
      filterOption={(input, option) => {
        if (option && option.children && input) {
          const parsedName = isString(option.children)
            ? option.children
            : option.children[0];

          return parsedName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }

        return false;
      }}
      {...props}
    >
      {data?.map((user) => (
        <Option value={user.id} key={user.id}>
          {user.name} ({ROLE_TO_HUMAN[user.role] || '-'})
        </Option>
      ))}
    </Select>
  );
};
