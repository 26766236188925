import { Button, Card } from 'antd';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import { ROUTES } from '~/constants';
import { useStoreState } from '~/global-state/hooks';
import { AuthService } from '~/services/Auth';

import Logo from '~/images/logo.jpg';

import styles from './styles.scss';

const LoginView: React.FC = () => {
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);

  const handleClick = useCallback(() => {
    AuthService.loginWithAuth0();
  }, []);

  if (isAuthenticated) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className={styles.root}>
        <Card
          className={styles.card}
          title={
            <div className={styles.logoContainer}>
              <img src={Logo} className={styles.logoImage} alt='Logo' />
            </div>
          }
        >
          <p>
            Witaj w aplikacji Karta Pacjeta!
            <br />
            Kliknij poniżej aby się zalogować.
          </p>
          <Button type='primary' onClick={handleClick} block>
            Logowanie
          </Button>
        </Card>
      </div>
    </>
  );
};

export default LoginView;
