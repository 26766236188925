import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePatient } from '~/queries/usePatients';
import { Skeleton } from 'antd';
import { findActiveOrRecentVisit, findVisitById } from '~/selectors/patient';

import { PatientRouter } from './Router';
import { PatientPageHeader } from '~/views/dashboard/patient/PageHeader';
import { Redirect } from 'react-router';
import { createPatientVisitRoute, ROUTES } from '~/constants';

export const PatientView = () => {
  const match = useRouteMatch<{ id: string; visitId?: string }>();
  const { data: patient, isLoading: isPatientLoading } = usePatient(
    match.params.id,
  );

  const selectedVisit =
    patient && match.params.visitId
      ? findVisitById(patient, match.params.visitId)
      : findActiveOrRecentVisit(patient);

  // If no visitId in the params, redirect user to the recent visit or currently active one
  if (patient && !match.params.visitId && selectedVisit) {
    const url = createPatientVisitRoute(
      ROUTES.dashboard.patient.visit.ROOT,
      patient.id,
      selectedVisit.id,
    );

    return <Redirect to={url} />;
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,.1)',
        padding: isPatientLoading ? 20 : 0,
      }}
    >
      <Skeleton loading={isPatientLoading} active>
        {patient && selectedVisit && (
          <div>
            <PatientPageHeader
              patient={patient}
              selectedVisit={selectedVisit}
            />
            <PatientRouter visit={selectedVisit} patient={patient} />
          </div>
        )}
      </Skeleton>
    </div>
  );
};
