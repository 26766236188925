import { Button, Card, Select, Form, Input } from 'antd';
import * as yup from 'yup';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddUser } from '~/queries';
import { Role } from '~/dtos';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(6, 'Minimalnie 6 znaków')
    .required('Imię i nazwisko jest wymagane'),
  email: yup
    .string()
    .email('To nie wyglada jak email')
    .required('Email jest wymagany'),
  password: yup.string().min(8, 'Hasło musi miec minimum 8 znaków').required(),
  role: yup.string().required(),
});

export const AddUserForm: React.FC = () => {
  const { handleSubmit, control, reset, formState } = useForm({
    resolver: yupResolver(schema),
  });
  const { mutate, isLoading } = useAddUser(reset);

  const onSubmit = useCallback(
    (data) => {
      mutate(data);
    },
    [mutate],
  );

  const { name, email, password, role } = formState.errors;

  return (
    <Card title='Dodawanie nowego użytkownika' style={{ marginTop: 16 }}>
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Form.Item
          label='Imię i nazwisko'
          required
          validateStatus={name ? 'error' : 'validating'}
          help={name ? name.message : undefined}
        >
          <Controller
            name='name'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Input onChange={field.onChange} value={field.value} />
            )}
          />
        </Form.Item>
        <Form.Item
          label='Email'
          required
          validateStatus={email ? 'error' : 'validating'}
          help={email ? email.message : undefined}
        >
          <Controller
            name='email'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Input onChange={field.onChange} value={field.value} />
            )}
          />
        </Form.Item>
        <Form.Item
          label='Hasło'
          required
          validateStatus={password ? 'error' : 'validating'}
          help={password ? password.message : undefined}
        >
          <Controller
            name='password'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Input onChange={field.onChange} value={field.value} />
            )}
          />
        </Form.Item>
        <Form.Item
          label='Rola'
          required
          validateStatus={role ? 'error' : 'validating'}
          help={role ? role.message : undefined}
        >
          <Controller
            name='role'
            control={control}
            defaultValue={Role.NURSE}
            render={({ field }) => (
              <Select defaultValue={Role.NURSE} onChange={field.onChange}>
                <Select.Option value={Role.NURSE}>Pielęgniarka</Select.Option>
                <Select.Option value={Role.ADMIN}>Administrator</Select.Option>
                <Select.Option value={Role.DOCTOR}>Doktor</Select.Option>
                <Select.Option value={Role.RECEPTIONIST}>
                  Recepcja
                </Select.Option>
                <Select.Option value={Role.DIETICIAN}>Dietetyk</Select.Option>
                <Select.Option value={Role.PSYCHOLOGIST}>
                  Psycholog
                </Select.Option>
              </Select>
            )}
          />
        </Form.Item>
        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          Dodaj użytkownika
        </Button>
      </Form>
    </Card>
  );
};
