import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { FileDownloadDTO } from '~/dtos';
import { handleError } from '~/queries/common';
import { DocumentService } from '~/services/Document';

export const useDownloadExaminationDocument = (
  documentId: string,
  onSuccess: (data: FileDownloadDTO) => void,
) =>
  useMutation<FileDownloadDTO, AxiosError>(
    ['download-examination-document', documentId],
    () => DocumentService.getExaminationDocument(documentId),
    {
      onSuccess: async (data) => {
        onSuccess(data);
      },
      onError: handleError,
    },
  );

export const useDownloadVisitDocument = (
  documentId: string,
  onSuccess: (data: FileDownloadDTO) => void,
) =>
  useMutation<FileDownloadDTO, AxiosError>(
    ['download-visit-document', documentId],
    () => DocumentService.getVisitDocument(documentId),
    {
      onSuccess: async (data) => {
        onSuccess(data);
      },
      onError: handleError,
    },
  );
