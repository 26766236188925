import { Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect } from 'react';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';

import { useStoreActions, useStoreState } from '~/global-state/hooks';
import { ErrorPageParams } from '~/components/GenericErrorPage';
import { ROUTES } from '~/constants';

const LoginCallbackView: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const authenticate = useStoreActions((actions) => actions.auth.authenticate);
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const data = useStoreState((s) => s.user.data);

  const handleRedirect = useCallback(() => {
    if (data && data.id) {
      history.push(ROUTES.dashboard.ROOT);
    }
  }, [data, history]);

  const handleAuthentication = () => authenticate().then(handleRedirect);

  const errorToRedirectPage = useCallback(
    (params?: ErrorPageParams) => {
      const urlParams = queryString.stringify({ ...params });
      const url = `${ROUTES.ERROR}?${urlParams}`;
      history.push(url);
    },
    [history],
  );

  useEffect(() => {
    if (isAuthenticated) {
      handleRedirect();
    } else if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication();
    } else {
      errorToRedirectPage({
        error: 'No authenticate params have been provided',
        errorDescription: 'In the URL there were no authentication parameters.',
        showHomeButton: true,
        showRetryButton: false,
      });
    }
  }, [data]); // eslint-disable-line

  return (
    <React.Fragment>
      <Helmet>
        <title>Logging in</title>
      </Helmet>

      <Card title='Witaj w aplikacji!' style={{ width: 320 }}>
        <p>Sprawdzanie użykownika</p>
        <LoadingOutlined />
      </Card>
    </React.Fragment>
  );
};

export default LoginCallbackView;
