import { QueryClient } from 'react-query';
import dayjs from 'dayjs';

import { withParentPrefix } from '~/utils/with-parent-prefix';
import { EpicrisisType } from '~/dtos';

export const ROOT_NODE = document.getElementById('root');

export const PROD_ENV = process.env.NODE_ENV === 'production';

export const GOOGLE_ANALYTICS_TAG_ID = process.env
  .GOOGLE_ANALYTICS_TAG_ID as string;
export const SENTRY_DSN = process.env.SENTRY_DSN as string;
export const SENTRY_ENV_NAME = process.env.SENTRY_ENV_NAME as string;
export const SENTRY_NORMALIZE_DEPTH = 10;

export const API_URL = process.env.API_URL as string;
export const COMMIT_HASH = process.env.COMMIT_HASH as string;

export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID as string;
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN as string;
export const AUTH0_REDIRECT_URI = process.env.AUTH0_REDIRECT_URI as string;
export const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE as string;
export const AUTH0_CONNECTION = process.env.AUTH0_CONNECTION as string;
export const AUTH0_ACCESS_TOKEN_KEY = 'AUTH0_ACCESS_TOKEN_KEY';
export const AUTH0_ID_TOKEN_KEY = 'AUTH0_ID_TOKEN_KEY';
export const AUTH0_EXPIRES_AT_KEY = 'AUTH0_EXPIRES_AT_KEY';
export const SUPPORT_EMAIL = 'karta-pacjeta@gedeonmedica.pl';

export const HOTJAR_SITE_ID = process.env.HOTJAR_SITE_ID as string | null;

export const META = {
  keywords: [].join(', '),
  description: '',
  title: !PROD_ENV ? 'Gedeon Medica - Development' : 'Gedeon Medica',
  titleTemplate: 'Gedeon Medica | %s',
  imgAlt: 'Gedeon Medica',
};

export const DEFAULT_ERROR_NAME = 'Error';
export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';
export const PROD_ERROR_MESSAGE = 'Something went wrong.';
export const NOT_FOUND_ERROR_MESSAGE = "It's a 404 error. Page not found.";
export const fullDateWithMonthFormat = (date: Date) =>
  dayjs(date).format('D MMMM YYYY');

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGIN_CALLBACK: '/login-callback',
  ERROR: '/error',
  dashboard: withParentPrefix('/dashboard', {
    USERS: '/users',
    PATIENTS: '/patients',
    ACTIVITY: '/activity',
    REPORTS: '/reports',
    patient: withParentPrefix('/patient/:id', {
      visit: withParentPrefix('/visit/:visitId', {
        ENTRY_OBSERVATIONS: '/entry-observations',
        OBSERVATIONS: '/observations',
        SPECIALIST_OBSERVATIONS: '/specialist-observations',
        PSYCHO_OBSERVATION_FORM: '/psycho-observation-form',
        NURSE_OBSERVATIONS: '/nurse-observations',
        NURSE_OBSERVATION_FORM: '/nurse-observation-form',
        EXAMINATIONS: '/examinations',
        FILES: '/files',
        EXAMINATIONS_FORM: '/examination-form',
        GENERATE_EXIT: '/generate-exit',
        OBSERVATION_FORM: '/observation-form',
        ENTRY_OBSERVATION_FORM: '/entry-observation/form',
        EPICRISIS_FORM: '/epicrisis-form/:type',
        EXIT: '/exit',
      }),
    }),
    PATIENT_FORM: '/patient/form',
    NEW_VISIT: '/new-visit/:id', // should be a patient id
    USER_PROFILE: '/profile',
    VISIT_FORM: '/visit/form',
  }),
};

export const insertIdToRoute = (route: string, id: string) =>
  route.replace(':id', id);

export const createPatientVisitRoute = (
  route: string,
  patientId: string,
  visitId: string,
) => route.replace(':id', patientId).replace(':visitId', visitId);

export const createAddEpicrisisRoute = (
  patientId: string,
  visitId: string,
  epicrisisType: EpicrisisType,
) =>
  ROUTES.dashboard.patient.visit.EPICRISIS_FORM.replace(':id', patientId)
    .replace(':visitId', visitId)
    .replace(':type', epicrisisType);

export const queryClient = new QueryClient();
