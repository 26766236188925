import RequestService from './Request';

import { UpdateObservationDTO, ObservationDTO } from '~/dtos';

class _ObservationService {
  getObservation(id: string): Promise<ObservationDTO> {
    return RequestService.client
      .get<ObservationDTO>(`/observations/${id}`)
      .then((response) => response.data);
  }

  removeObservation(id: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/observations/${id}`)
      .then(() => Promise.resolve());
  }

  updateObservation(
    id: string,
    data: UpdateObservationDTO,
  ): Promise<ObservationDTO> {
    return RequestService.client
      .patch<ObservationDTO>(`/observations/${id}`, data)
      .then((response) => response.data);
  }
}

const ObservationService = new _ObservationService();

export { ObservationService, _ObservationService };
