import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';

import initStore from './global-state';
import { initSentry } from '~/utils/sentry';
import ReactGA from 'react-ga4';
import { ROOT_NODE, GOOGLE_ANALYTICS_TAG_ID } from '~/constants';

import App from './App';

import 'antd/dist/antd.css';
import './styles/main.scss';

ReactGA.initialize(GOOGLE_ANALYTICS_TAG_ID);

// Important!
// Sentry must be initialized before the store is created, as we use redux sentry middleware
initSentry();

const store = initStore();

const render = () => {
  ReactDOM.render(
    <StoreProvider store={store}>
      <App />
    </StoreProvider>,
    ROOT_NODE as HTMLElement,
  );
};

render();
