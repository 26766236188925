import React, { CSSProperties } from 'react';
import { Select } from 'antd';
import { isString } from 'lodash';

const { Option } = Select;

export enum EXAMINATION_TYPE {
  Morfologia = 'Morfologia',
  Biochemia = 'Biochemia',
  EKG = 'EKG',
  Inne = 'Inne',
}

const examinationTypes = Object.values(EXAMINATION_TYPE);

export interface Props {
  className?: string;
  selectedExamination?: string;
  onSelect?: (examination: EXAMINATION_TYPE) => void;
  onClear?: () => void;
  style?: CSSProperties;
}

export const ExaminationSelect: React.FC<Props> = (props) => {
  return (
    <Select
      showSearch
      allowClear
      value={props.selectedExamination}
      style={props.style}
      placeholder='Wybierz typ badania'
      optionFilterProp='children'
      onChange={props.onSelect}
      onClear={props.onClear}
      filterOption={(input, option) => {
        if (option && option.children && input) {
          const parsedName = isString(option.children)
            ? option.children
            : option.children[0];

          return parsedName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }

        return false;
      }}
    >
      {examinationTypes.map((examination) => (
        <Option value={examination} key={examination}>
          {examination}
        </Option>
      ))}
    </Select>
  );
};
