import React from 'react';
import { Button, Card, Space, Tag } from 'antd';
import {
  PatientDTO,
  Status,
  STATUS_TO_COLOR,
  STATUS_TO_HUMAN_STRING,
  VisitDTO,
} from '~/dtos';
import { fullDateWithMonthFormat, ROUTES } from '~/constants';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import dayjs from 'dayjs';

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const VisitDetailsCard = ({ visit }: Props) => {
  const history = useHistory();

  return (
    <Card
      extra={
        <Space>
          <Tag color={STATUS_TO_COLOR[visit.status]}>
            {STATUS_TO_HUMAN_STRING[visit.status]}
          </Tag>
          <Button
            disabled={visit.status !== Status.ACTIVE}
            size='small'
            onClick={() =>
              history.push(ROUTES.dashboard.VISIT_FORM, {
                visit,
              })
            }
          >
            <EditOutlined />
          </Button>
        </Space>
      }
      title='Dane o pobycie'
      type='inner'
      size='small'
    >
      <p>
        <b>Numer księgi głównej: </b>
        {visit.ledgerIndex}
        <br />
        <b>Placówka:</b> {startCase(visit.department)}
        <br />
        Od {fullDateWithMonthFormat(visit.startedAt)} do{' '}
        {visit.endedAt ? fullDateWithMonthFormat(visit.endedAt) : 'obecnie'}
      </p>

      {visit.endedAt && (
        <div>
          <p>
            Pacjent zakończył wizytę: <b>{dayjs(visit.endedAt).fromNow()}</b>
            <br />
            Wizyta trwała:{' '}
            <b>{dayjs(visit.endedAt).diff(visit.startedAt, 'day')} dni</b>
          </p>

          <b>Powód:</b>
          <br />
          {visit.endReason}
        </div>
      )}
    </Card>
  );
};
