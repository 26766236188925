import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextArea from 'antd/es/input/TextArea';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Button, Card, Form } from 'antd';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import { useCreateEpicrisis, useUpdateEpicrisis } from '~/queries/useVisit';
import { EpicrisisDTO, EpicrisisType, PatientDTO, VisitDTO } from '~/dtos';
import { GoBackButton } from '~/components/GoBackButton';

interface FormSchema {
  type?: EpicrisisType;
  description: string;
  treatment: string;
  state: string;
}

const schema = yup.object().shape({
  type: yup.string().required('To pole jest wymagane.'),
  description: yup.string().required('To pole jest wymagane.'),
  treatment: yup.string().required('To pole jest wymagane.'),
  state: yup.string().required('To pole jest wymagane.'),
});

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const EpicrisiseForm = ({ visit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation<{ epicrisise?: EpicrisisDTO }>();
  const isEditing = Boolean(location.state?.epicrisise);

  const routeMatch = useRouteMatch<{ type: EpicrisisType }>();
  const { handleSubmit, control, formState, setValue } = useForm<FormSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: routeMatch.params.type,
    },
  });

  useEffect(() => {
    if (location.state?.epicrisise) {
      const { epicrisise } = location.state;
      setValue('description', epicrisise.description);
      setValue('treatment', epicrisise.treatment);
      setValue('state', epicrisise.state);
    }
  }, [location.state, setValue]);

  const { mutate: createEpicrisis, isLoading } = useCreateEpicrisis(
    visit.id,
    () => {
      history.push(
        createPatientVisitRoute(
          ROUTES.dashboard.patient.visit.EXIT,
          patient.id,
          visit.id,
        ),
      );
    },
  );

  const { mutate: updateEpicrisis } = useUpdateEpicrisis(
    visit.id,
    location.state?.epicrisise?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    ({ type, ...restData }) => {
      if (isEditing) {
        updateEpicrisis(restData);
      } else {
        createEpicrisis({ ...restData, type });
      }
    },
    [createEpicrisis, updateEpicrisis, isEditing],
  );

  const {
    errors: { description, treatment, state },
  } = formState;

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawania'
          } epikryzy - ${routeMatch.params.type}`}
        />
      }
      style={{ marginTop: 16 }}
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Form.Item
          label='Opis pacjenta podczas pobytu'
          validateStatus={description ? 'error' : 'validating'}
          help={description ? description.message : undefined}
          required
        >
          <Controller
            name='description'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea
                {...field}
                autoSize
                placeholder='Opisz pacjenta podczas pobytu'
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label='Zastosowane leczenie'
          validateStatus={treatment ? 'error' : 'validating'}
          help={treatment ? treatment.message : undefined}
          required
        >
          <Controller
            name='treatment'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea
                {...field}
                autoSize
                placeholder='Opisz zastosowane leczenie'
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label='Stan pacjenta przy wypisie'
          validateStatus={state ? 'error' : 'validating'}
          help={state ? state.message : undefined}
          required
        >
          <Controller
            name='state'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea
                {...field}
                autoSize
                placeholder='Opisz stan pacjenta przy wypisie'
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>

        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          {isEditing ? 'Edytuj' : 'Dodaj'} epikryze
        </Button>
      </Form>
    </Card>
  );
};
