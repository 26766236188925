import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Department, PatientDTO, Status, UserDTO } from '~/dtos';
import { ColumnsType } from 'antd/es/table';
import { debounce, startCase } from 'lodash';
import { UserAddOutlined } from '@ant-design/icons';
import { Table, Input, Button } from 'antd';
import { DepartmentSelect } from '~/components/DepartmentSelect';
import { usePatients } from '~/queries/usePatients';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createPatientVisitRoute,
  fullDateWithMonthFormat,
  insertIdToRoute,
  ROUTES,
} from '~/constants';
import { findActiveVisit, findLatestVisit } from '~/selectors/patient';
import moment from 'moment';
import queryString from 'query-string';
import { useOnSearchShortCut } from '~/hooks/useOnSearchShortCut';
import Title from 'antd/lib/typography/Title';
import {
  PatientsFilterSelect,
  PATIENTS_FILTER,
} from '~/components/PatientsFilterSelect';
import { DoctorName } from '~/components/DoctorName';
import { useQuery } from 'react-query';
import { UserService } from '~/services/User';
import { SorterResult, SortOrder } from 'antd/es/table/interface';

enum ColumnKey {
  PATIENT = 'patient',
  STAY = 'stay',
}

const generateColumns = (sort: Sort): ColumnsType<PatientDTO> => [
  {
    title: 'Dane pacjenta',
    key: ColumnKey.PATIENT,
    width: '40%',
    render: (_, { firstName, lastName, personalIdentityNumber }) => (
      <div>
        <Title level={5} style={{ margin: 0 }}>
          {firstName} {lastName}
        </Title>
        <b>PESEL</b>: {personalIdentityNumber}
      </div>
    ),
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    defaultSortOrder: sort.key === ColumnKey.PATIENT ? sort.order : undefined,
  },
  {
    title: 'Pobyt',
    key: ColumnKey.STAY,
    defaultSortOrder: sort.key === ColumnKey.STAY ? sort.order : undefined,
    width: '40%',
    render: (_, patient) => {
      const latestVisit = findLatestVisit(patient);
      const endedAtFormatted = latestVisit?.endedAt
        ? ` ${fullDateWithMonthFormat(latestVisit?.endedAt)}`
        : '';

      if (latestVisit?.status === Status.ACTIVE) {
        return (
          <div>
            <b>Placówka: {startCase(latestVisit.department)}</b>
            <br />
            <b>Rozpoczęto:</b> {fullDateWithMonthFormat(latestVisit.startedAt)}
          </div>
        );
      }

      if (latestVisit?.status === Status.CANCELLED) {
        return (
          <div>
            Ostatnia wizyta została anulowana
            {endedAtFormatted ? ` dnia ${endedAtFormatted}.` : '.'}
          </div>
        );
      }

      return (
        <div>
          Pacjent został wypisany
          {endedAtFormatted ? ` dnia ${endedAtFormatted}.` : '.'}
        </div>
      );
    },
    sorter: (a, b) => {
      const activeVisitA = findActiveVisit(a);
      const activeVisitB = findActiveVisit(b);
      return (
        moment(activeVisitA?.startedAt).unix() -
        moment(activeVisitB?.startedAt).unix()
      );
    },
  },
  {
    title: 'Lekarz prowadzący',
    width: '20%',
    align: 'center',
    render: (_, patient) => {
      return <DoctorName doctor={patient.doctor} />;
    },
  },
];

interface QueryParams {
  patientsFilter: PATIENTS_FILTER | null;
  q: string | null;
  department: Department | null;
  page: number;
  sortKey?: string | number;
  sortOrder?: SortOrder;
}

interface Sort {
  key?: string | number;
  order?: SortOrder;
}

export const PatientsView = () => {
  const history = useHistory();
  const location = useLocation();

  const { data: userData } = useQuery<UserDTO>('user', () =>
    UserService.getMe(),
  );

  const queryParams = useMemo(
    () => queryString.parse(location.search) as unknown as QueryParams,
    [location.search],
  );

  const [search, setSearch] = useState<string>(queryParams.q || '');
  const [selectedDepartment, setDepartment] = useState<null | Department>(
    queryParams.department || null,
  );

  const [sort, setSort] = useState<Sort>({
    key: queryParams.sortKey,
    order: queryParams.sortOrder,
  });

  const [patientsFilter, setPatientsFilter] = useState<PATIENTS_FILTER>(
    queryParams.patientsFilter || PATIENTS_FILTER.ACTIVE,
  );

  const [page, setPage] = useState(queryParams.page || 1);

  // FIXME: Update typings to InputRef after updating Antd
  const inputRef = React.useRef<any>(null);

  const { isLoading, data } = usePatients({
    department: selectedDepartment ? selectedDepartment : undefined,
    q: search,
  });

  const filteredOutData = useMemo(() => {
    if (!data) {
      return [];
    }

    let filtered = [...data];
    if (patientsFilter) {
      if (patientsFilter === PATIENTS_FILTER.MY_PATIENTS) {
        filtered = filtered.filter((p) => p.doctorId === userData?.id);
      }

      if (patientsFilter === PATIENTS_FILTER.ACTIVE) {
        filtered = filtered.filter((p) => {
          const latestVisit = findLatestVisit(p);
          return latestVisit && latestVisit.status === Status.ACTIVE;
        });
      }

      if (patientsFilter === PATIENTS_FILTER.ARCHIVED) {
        filtered = filtered.filter((p) => {
          const latestVisit = findLatestVisit(p);
          return (
            latestVisit &&
            [Status.CANCELLED, Status.FINISHED].includes(latestVisit?.status)
          );
        });
      }
    }

    return filtered;
  }, [data, patientsFilter, userData]);

  useOnSearchShortCut(() => {
    inputRef.current?.focus({
      cursor: 'start',
    });
  });

  const debouncedSetQ = debounce(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(value.target.value);
    },
    300,
  );

  const handleSearchChange = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSetQ(value);
    },
    [debouncedSetQ],
  );

  useEffect(() => {
    const params: QueryParams = {
      department: selectedDepartment,
      patientsFilter,
      q: search,
      page,
      sortKey: sort.key,
      sortOrder: sort.order,
    };

    history.replace({
      search: queryString.stringify(params, {
        skipEmptyString: true,
        skipNull: true,
      }),
    });
  }, [history, selectedDepartment, patientsFilter, search, page, sort]);

  const columns = useMemo(() => generateColumns(sort), [sort]);

  return (
    <div>
      <div style={{ marginBottom: 16, display: 'flex' }}>
        <PatientsFilterSelect
          value={patientsFilter}
          onSelect={(filter) => {
            setPage(1);
            setPatientsFilter(filter);
          }}
          style={{ marginRight: 8 }}
        />
        <Input.Search
          placeholder='Wyszukaj po imieniu, nazwisku lub peselu'
          onSearch={(s) => {
            setPage(1);
            setSearch(s);
          }}
          onChange={handleSearchChange}
          enterButton
          allowClear
          style={{ marginRight: 8, flex: 1 }}
          ref={inputRef}
          defaultValue={queryParams.q || ''}
        />
        <DepartmentSelect
          onClear={() => setDepartment(null)}
          onSelect={(dept) => {
            setPage(1);
            setDepartment(dept);
          }}
          selectedDepartment={selectedDepartment}
          style={{ marginRight: 8 }}
        />
        <Button
          type='primary'
          icon={<UserAddOutlined />}
          onClick={() => history.push(ROUTES.dashboard.PATIENT_FORM)}
        >
          Dodaj pacjenta
        </Button>
      </div>

      <Table<PatientDTO>
        onRow={(patient) => ({
          onClick: () => {
            const latestVisit = findLatestVisit(patient);

            let route = insertIdToRoute(
              ROUTES.dashboard.patient.ROOT,
              patient.id,
            );

            if (latestVisit) {
              route = createPatientVisitRoute(
                ROUTES.dashboard.patient.visit.ROOT,
                patient.id,
                latestVisit.id,
              );
            }

            history.push(route);
          },
        })}
        rowClassName='clickable-row'
        rowKey='id'
        columns={columns}
        dataSource={filteredOutData}
        loading={isLoading}
        bordered
        pagination={{
          current: Number(queryParams.page) > 0 ? Number(queryParams.page) : 1,
          onChange: (page) => {
            setPage(page);
          },
          defaultPageSize: 10,
          hideOnSinglePage: true,
        }}
        scroll={{
          y: window.innerHeight - 270,
        }}
        onChange={(p, f, sort: SorterResult<PatientDTO>) => {
          if (sort.columnKey && sort.order) {
            setSort({ key: sort.columnKey, order: sort.order });
          } else {
            setSort({ key: undefined, order: undefined });
          }
        }}
      />
    </div>
  );
};
