import React from 'react';
import { DocumentDTO, VisitDTO } from '~/dtos';
import { useDownloadVisitDocument } from '~/queries/useDocument';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { fullDateWithMonthFormat } from '~/constants';

export interface Props {
  visit: VisitDTO;
  document: DocumentDTO;
}

export const ExitDetails = ({ visit, document }: Props) => {
  const { isLoading, mutate } = useDownloadVisitDocument(
    document.id,
    (data) => {
      window.open(data.downloadUrl, '_blank');
    },
  );

  return (
    <Space
      direction='vertical'
      style={{ marginBottom: 16, width: '100%' }}
      align='center'
    >
      {visit.endedAt ? (
        <Typography.Title level={3}>
          Ta wizyta została zakończona {fullDateWithMonthFormat(visit.endedAt)}{' '}
          z powodu &quot;{visit.endReason}
          &quot;.
        </Typography.Title>
      ) : null}
      <Button
        type='primary'
        icon={<FilePdfOutlined />}
        loading={isLoading}
        onClick={() => mutate()}
      >
        Ściągnij: {document.fileName}
      </Button>
    </Space>
  );
};
