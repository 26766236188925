import React from 'react';
import { Button, Card } from 'antd';
import { PatientDTO } from '~/dtos';
import { fullDateWithMonthFormat, ROUTES } from '~/constants';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

interface Props {
  patient: PatientDTO;
}

export const PatientDetailsCard = ({ patient }: Props) => {
  const history = useHistory();

  return (
    <Card
      title='Dane o pacjencie'
      extra={
        <Button
          size='small'
          onClick={() =>
            history.push(ROUTES.dashboard.PATIENT_FORM, {
              patient,
            })
          }
        >
          <EditOutlined />
        </Button>
      }
      type='inner'
      size='small'
    >
      <b>Imię:</b> {patient.firstName}
      <br />
      <b>Nazwisko:</b> {patient.lastName}
      <br />
      <b>Data urodzenia:</b> {fullDateWithMonthFormat(patient.dateOfBirth)} (
      {moment().diff(moment(patient.dateOfBirth), 'years')} lat)
      <br />
      <b>Pesel:</b> {patient.personalIdentityNumber}
      <br />
      <br />
      <b>Adres zamieszkania:</b> {patient.street}
      <br />
      <b>Numer telefonu:</b> {patient.phoneNumber || 'Brak'}
      <br />
      <b>Adres e-mail:</b> {patient.email || 'Brak'}
      <br />
      <b>Telefon do opiekuna prawnego:</b>{' '}
      {patient.phoneNumberLegalGuardian || 'Brak'}
      <br />
      <b>Telefon do osoby upożnionej do kontaktu:</b>{' '}
      {patient.phoneNumberEntitledContactPerson || 'Brak'}
      <br />
      <b>Kod pocztowy:</b> {patient.zip} {patient.city}
      <br />
      <b>Skąd przyszedł:</b> {patient.from}
    </Card>
  );
};
