import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { message } from 'antd';

import {
  ConfirmVisitExitDTO,
  CreateDrugDTO,
  CreateEntryObservationDTO,
  CreateEpicrisisDTO,
  CreateExaminationDTO,
  CreateObservationDTO,
  CreateVisitDTO,
  DrugDTO,
  EntryObservationDTO,
  EpicrisisDTO,
  ExaminationDTO,
  GenerateExcerptDocumentDTO,
  GenerateExcerptDocumentResponseDTO,
  ObservationDTO,
  UpdateVisitDTO,
  VisitDTO,
  ChartsResponseDTO,
  PsychoObservationDTO,
  CreatePsychoObservationDTO,
  UpdateObservationDTO,
  UpdateEntryObservationDTO,
  UpdateExaminationDTO,
  UpdateEpicrisisDTO,
  UpdateDrugDTO,
  CancelVisitDTO,
  VisitFileDTO,
  CreateVisitFileDTO,
  FileDownloadDTO,
  NurseObservationDTO,
  CreateNurseObservationDTO,
} from '~/dtos';
import { VisitService } from '~/services/Visit';
import { queryClient } from '~/constants';
import { handleError } from '~/queries/common';
import { PATIENT_DETAILS_KEY } from '~/queries/usePatients';
import { DrugService } from '~/services/Drug';
import { ObservationService } from '~/services/Observation';
import { EntryObservationService } from '~/services/EntryObservation';

export const VISIT = 'visit';
export const VISIT_UPDATE_STAY = 'visit-update-stay';
export const VISIT_CHARTS_DATA = 'visit-charts-data';
export const VISIT_OBSERVATIONS_KEY = 'visit-observations';
export const VISIT_ENTRY_OBSERVATIONS_KEY = 'visit-entry-observations';
export const VISIT_UPDATE_ENTRY_OBSERVATIONS_KEY =
  'visit-update-entry-observation';
export const VISIT_UPDATE_OBSERVATIONS_KEY = 'visit-update-observations-key';
export const VISIT_DRUGS = 'visit-drugs';
export const VISIT_UPDATE_DRUGS = 'visit-update-drugs';
export const VISIT_EXAMINATIONS = 'visit-examinations';
export const VISIT_UPDATE_EXAMINATIONS = 'visit-update-examinations';
export const VISIT_EPICRISISES = 'visit-epicrisises';
export const VISIT_UPDATE_EPICRISIES = 'visit-update-epicrisises';
export const VISIT_UPDATE = 'update-visit';
export const VISIT_PSYCHOTHERAPEUTIC_OBSERVATION = 'visit-psycho-observation';
export const VISIT_NURSE_OBSERVATION = 'visit-nurse-observation';

export const useVisitObservations = (visitId: string) =>
  useQuery<ObservationDTO[]>([VISIT_OBSERVATIONS_KEY, visitId], () =>
    VisitService.getVisitObservations(visitId),
  );

export const useVisit = (visitId: string) =>
  useQuery<VisitDTO>([VISIT, visitId], () => VisitService.getVisit(visitId));

export const useVisitChartsData = (visitId: string) =>
  useQuery<ChartsResponseDTO>([VISIT_CHARTS_DATA, visitId], () =>
    VisitService.chartsData(visitId),
  );

export const useCreateObservation = (
  visitId: string,
  onSuccess?: (patient: ObservationDTO) => void,
) =>
  useMutation<ObservationDTO, AxiosError, CreateObservationDTO>(
    'create-visit-observation',
    (data: CreateObservationDTO) =>
      VisitService.createVisitObservation(visitId, data),
    {
      onSuccess: async (drug) => {
        await queryClient.refetchQueries([VISIT_OBSERVATIONS_KEY, visitId]);

        if (onSuccess) {
          onSuccess(drug);
        }
      },
      onError: handleError,
    },
  );

export const useUpdateObservations = (
  visitId: string,
  observationId: string,
  onSuccess?: (observation: ObservationDTO) => void,
) =>
  useMutation<ObservationDTO, AxiosError, UpdateObservationDTO>(
    VISIT_UPDATE_OBSERVATIONS_KEY,
    (data: UpdateObservationDTO) =>
      ObservationService.updateObservation(observationId, data),
    {
      onSuccess: async (observation) => {
        await queryClient.refetchQueries([VISIT_OBSERVATIONS_KEY, visitId]);

        if (onSuccess) {
          onSuccess(observation);
        }
      },
      onError: handleError,
    },
  );

export const useUpdateVisit = (
  id: string,
  patientId: string,
  onSuccess?: (visit: VisitDTO) => void,
) =>
  useMutation<VisitDTO, AxiosError, UpdateVisitDTO>(
    VISIT_UPDATE,
    (data: UpdateVisitDTO) => VisitService.updateVisit(id, data),
    {
      onSuccess: async (visit) => {
        await queryClient.refetchQueries([PATIENT_DETAILS_KEY, patientId]);

        if (onSuccess) {
          onSuccess(visit);
        }
      },
      onError: handleError,
    },
  );

export const useVisitEntryObservations = (visitId: string) =>
  useQuery<EntryObservationDTO[]>([VISIT_ENTRY_OBSERVATIONS_KEY, visitId], () =>
    VisitService.getVisitEntryObservations(visitId),
  );

export const useCreateEntryObservation = (
  visitId: string,
  onSuccess?: (patient: EntryObservationDTO) => void,
) =>
  useMutation<EntryObservationDTO, AxiosError, CreateEntryObservationDTO>(
    'create-visit-entry-observation',
    (data: CreateEntryObservationDTO) =>
      VisitService.createVisitEntryObservation(visitId, data),
    {
      onSuccess: async (drug) => {
        await queryClient.refetchQueries([
          VISIT_ENTRY_OBSERVATIONS_KEY,
          visitId,
        ]);

        if (onSuccess) {
          onSuccess(drug);
        }
      },
      onError: handleError,
    },
  );

export const useUpdateEntryObservation = (
  visitId: string,
  entryObservationId: string,
  onSuccess?: (patient: EntryObservationDTO) => void,
) =>
  useMutation<EntryObservationDTO, AxiosError, UpdateEntryObservationDTO>(
    VISIT_UPDATE_ENTRY_OBSERVATIONS_KEY,
    (data: UpdateEntryObservationDTO) =>
      EntryObservationService.updateEntryObservation(entryObservationId, data),
    {
      onSuccess: async (drug) => {
        await queryClient.refetchQueries([
          VISIT_ENTRY_OBSERVATIONS_KEY,
          visitId,
        ]);

        if (onSuccess) {
          onSuccess(drug);
        }
      },
      onError: handleError,
    },
  );

export const useVisitDrugs = (visitId: string) =>
  useQuery<DrugDTO[]>([VISIT_DRUGS, visitId], () =>
    VisitService.getVisitDrugs(visitId),
  );

export const useCreateVisitDrug = (
  visitId: string,
  onSuccess?: (patient: DrugDTO) => void,
) =>
  useMutation<DrugDTO, AxiosError, CreateDrugDTO>(
    'create-visit-drug',
    (data: CreateDrugDTO) => VisitService.createVisitDrug(visitId, data),
    {
      onSuccess: async (drug) => {
        await queryClient.refetchQueries([VISIT_DRUGS, visitId]);

        if (onSuccess) {
          onSuccess(drug);
        }
      },
      onError: handleError,
    },
  );

export const useRemoveVisitDrug = (visitId: string) =>
  useMutation<DrugDTO, AxiosError, string>(
    'remove-visit-drug',
    (drugId: string) => VisitService.removeVisitDrug(visitId, drugId),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([VISIT_DRUGS, visitId]);
      },
      onError: handleError,
    },
  );

export const useUpdateVisitDrug = (
  visitId: string,
  drugId: string,
  onSuccess?: (visit: DrugDTO) => void,
) =>
  useMutation<DrugDTO, AxiosError, UpdateDrugDTO>(
    VISIT_UPDATE_DRUGS,
    (data: UpdateDrugDTO) => DrugService.updateDrug(drugId, data),
    {
      onSuccess: async (drug) => {
        await queryClient.refetchQueries([VISIT_DRUGS, visitId]);

        if (onSuccess) {
          onSuccess(drug);
        }
      },
      onError: handleError,
    },
  );

export const useVisitExaminations = (visitId: string) =>
  useQuery<ExaminationDTO[]>([VISIT_EXAMINATIONS, visitId], () =>
    VisitService.getVisitExaminations(visitId),
  );

export const useAddExamination = (visitId: string, onSuccess: () => void) =>
  useMutation<ExaminationDTO, AxiosError, CreateExaminationDTO>(
    'add-examination',
    (data) => VisitService.createVisitExamination(visitId, data),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries([VISIT_EXAMINATIONS, visitId]);
        message.success('Dodano nowe badanie');
        onSuccess();
      },
      onError: handleError,
    },
  );
export const useUpdateExamination = (
  visitId: string,
  examinationId: string,
  onSuccess?: (viist: ExaminationDTO) => void,
) =>
  useMutation<ExaminationDTO, AxiosError, UpdateExaminationDTO>(
    VISIT_UPDATE_EXAMINATIONS,
    (data: UpdateExaminationDTO) =>
      VisitService.updateVisitExamination(examinationId, data),
    {
      onSuccess: async (visit) => {
        await queryClient.refetchQueries([VISIT_EXAMINATIONS, visitId]);

        if (onSuccess) {
          onSuccess(visit);
        }
      },
      onError: handleError,
    },
  );

export const useVisitEpicrisises = (visitId: string) =>
  useQuery<EpicrisisDTO[]>([VISIT_EPICRISISES, visitId], () =>
    VisitService.getVisitEpicrisises(visitId),
  );

export const useCreateEpicrisis = (
  visitId: string,
  onSuccess?: (patient: EpicrisisDTO) => void,
) =>
  useMutation<EpicrisisDTO, AxiosError, CreateEpicrisisDTO>(
    'create-visit-epicrisis',
    (data: CreateEpicrisisDTO) =>
      VisitService.createVisitEpicrisis(visitId, data),
    {
      onSuccess: async (drug) => {
        await queryClient.refetchQueries([VISIT_EPICRISISES, visitId]);

        if (onSuccess) {
          onSuccess(drug);
        }
      },
      onError: handleError,
    },
  );

export const useUpdateEpicrisis = (
  visitId: string,
  epicrisisId: string,
  onSuccess?: (visit: EpicrisisDTO) => void,
) =>
  useMutation<EpicrisisDTO, AxiosError, UpdateEpicrisisDTO>(
    VISIT_UPDATE_EXAMINATIONS,
    (data: UpdateEpicrisisDTO) =>
      VisitService.updateVisitEpicrisis(epicrisisId, data),
    {
      onSuccess: async (visit) => {
        await queryClient.refetchQueries([VISIT_EPICRISISES, visitId]);

        if (onSuccess) {
          onSuccess(visit);
        }
      },
      onError: handleError,
    },
  );

export const useGenerateExcerptDocument = (visitId: string) =>
  useMutation<
    GenerateExcerptDocumentResponseDTO,
    AxiosError,
    GenerateExcerptDocumentDTO
  >(
    'generate-excerpt-document',
    (data: GenerateExcerptDocumentDTO) =>
      VisitService.generateExcerptDocument(visitId, data),
    {
      onError: handleError,
    },
  );

export const useCancelVisit = (
  visitId: string,
  onSuccess?: (visit: VisitDTO) => void,
) =>
  useMutation<VisitDTO, AxiosError, CancelVisitDTO>(
    'cancel-visit',
    (data: CancelVisitDTO) => VisitService.cancelVisit(visitId, data),
    {
      onError: handleError,
      onSuccess: async (visit) => {
        await queryClient.refetchQueries([PATIENT_DETAILS_KEY]);

        if (onSuccess) {
          onSuccess(visit);
        }
      },
    },
  );

export const useConfirmVisitExit = (
  patientId: string,
  visitId: string,
  onSuccess?: (patient: VisitDTO) => void,
) =>
  useMutation<VisitDTO, AxiosError, ConfirmVisitExitDTO>(
    'generate-excerpt-document',
    (data: ConfirmVisitExitDTO) => VisitService.confirmExit(visitId, data),
    {
      onSuccess: async (visit) => {
        await queryClient.refetchQueries(PATIENT_DETAILS_KEY);

        if (onSuccess) {
          onSuccess(visit);
        }
      },
      onError: handleError,
    },
  );

export const useCreateVisit = (
  patientId: string,
  onSuccess?: (patient: VisitDTO) => void,
) =>
  useMutation<VisitDTO, AxiosError, CreateVisitDTO>(
    'create-visit',
    (data: CreateVisitDTO) => VisitService.createVisit(patientId, data),
    {
      onSuccess: async (visit) => {
        if (onSuccess) {
          onSuccess(visit);
        }
      },
      onError: handleError,
    },
  );

export const usePsychoObservations = (visitId: string) =>
  useQuery<PsychoObservationDTO[]>(
    [VISIT_PSYCHOTHERAPEUTIC_OBSERVATION, visitId],
    () => VisitService.visitPsychoObservations(visitId),
  );

export const useCreatePsychoObservations = (
  visitId: string,
  onSuccess?: (psychoObservation: PsychoObservationDTO) => void,
) =>
  useMutation<PsychoObservationDTO, AxiosError, CreatePsychoObservationDTO>(
    'create-visit-psycho-observation',
    (data: CreatePsychoObservationDTO) =>
      VisitService.createPsychoObservation(visitId, data),
    {
      onSuccess: async (psychoObservation) => {
        await queryClient.refetchQueries([
          VISIT_PSYCHOTHERAPEUTIC_OBSERVATION,
          visitId,
        ]);
        message.success('Dodano nową psychoterapeutyczną obserwację');

        if (onSuccess) {
          onSuccess(psychoObservation);
        }
      },
      onError: handleError,
    },
  );

export const useNurseObservations = (visitId: string) =>
  useQuery<PsychoObservationDTO[]>([VISIT_NURSE_OBSERVATION, visitId], () =>
    VisitService.visitNurseObservations(visitId),
  );

export const useCreateNurseObservations = (
  visitId: string,
  onSuccess?: (psychoObservation: NurseObservationDTO) => void,
) =>
  useMutation<NurseObservationDTO, AxiosError, CreateNurseObservationDTO>(
    'create-visit-Nurse-observation',
    (data: CreateNurseObservationDTO) =>
      VisitService.createNurseObservation(visitId, data),
    {
      onSuccess: async (nurseObservation) => {
        await queryClient.refetchQueries([VISIT_NURSE_OBSERVATION, visitId]);
        message.success('Dodano nową notatke pielęgniarską');

        if (onSuccess) {
          onSuccess(nurseObservation);
        }
      },
      onError: handleError,
    },
  );

export const useVisitFiles = (visitId: string) =>
  useQuery<VisitFileDTO[]>(['visit-files', visitId], () =>
    VisitService.getVisitFiles(visitId),
  );

export const useDownloadVisitFile = (
  fileId: string,
  onSuccess: (data: FileDownloadDTO) => void,
) =>
  useMutation<FileDownloadDTO, AxiosError>(
    ['visit-file', fileId],
    () => VisitService.downloadFileVisitDocument(fileId),
    {
      onSuccess: async (data) => {
        onSuccess(data);
      },
      onError: handleError,
    },
  );

export const useDeleteVisitFile = (visitId: string) =>
  useMutation<void, AxiosError, { fileId: string }>(
    'delete-visit-file',
    (data) => VisitService.deleteFileVisitDocument(data.fileId),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['visit-files', visitId]);
        message.success('Usunuęto dokument');
      },
      onError: handleError,
    },
  );

export const useCreateVisitFile = (
  visitId: string,
  onSuccess?: (visitFile: VisitFileDTO) => void,
) =>
  useMutation<VisitFileDTO, AxiosError, CreateVisitFileDTO>(
    'create-visit-file',
    (data: CreateVisitFileDTO) => VisitService.createVisitFile(visitId, data),
    {
      onSuccess: async (visitFile) => {
        await queryClient.refetchQueries(['visit-files', visitId]);
        message.success('Dodano nowy dokument');

        if (onSuccess) {
          onSuccess(visitFile);
        }
      },
      onError: handleError,
    },
  );
