import { useMutation, useQuery } from 'react-query';
import {
  CreatePatientDTO,
  PatientDTO,
  PatientQuery,
  UpdatePatientDTO,
} from '~/dtos';
import { AxiosError } from 'axios';
import { queryClient } from '~/constants';
import { message } from 'antd';
import { handleError } from '~/queries/common';
import { PatientService } from '~/services/Patient';

export const PATIENTS_KEY = 'patients';
export const PATIENT_DETAILS_KEY = 'patient-details';
export const PATIENT_UPDATE = 'patient-update';

export const usePatients = (query: PatientQuery) =>
  useQuery<PatientDTO[]>([PATIENTS_KEY, query], () =>
    PatientService.getPatients(query),
  );

export const usePatient = (id: string) =>
  useQuery<PatientDTO>([PATIENT_DETAILS_KEY, id], () => {
    return PatientService.getPatient(id);
  });

export const useAddPatient = (onSuccess: (patient: PatientDTO) => void) =>
  useMutation<PatientDTO, AxiosError, CreatePatientDTO>(
    'add-patient',
    (data) => PatientService.createPatient(data),
    {
      onSuccess: async (patient) => {
        await queryClient.refetchQueries(PATIENTS_KEY);
        message.success('Dodano nowego pacjenta');
        onSuccess(patient);
      },
      onError: handleError,
    },
  );

export const useUpdatePatient = (
  patientId: string,
  onSuccess?: (patient: PatientDTO) => void,
) =>
  useMutation<PatientDTO, AxiosError, UpdatePatientDTO>(
    [PATIENT_UPDATE, patientId],
    (data: UpdatePatientDTO) => {
      if (!patientId) {
        throw new Error('Missing ID');
      }

      return PatientService.updatePatient(patientId, data);
    },
    {
      onError: handleError,
      onSuccess: async (patient) => {
        await queryClient.refetchQueries([PATIENTS_KEY, patientId]);
        await queryClient.refetchQueries([PATIENT_DETAILS_KEY, patientId]);

        message.success('Zaktualizowano dane pacjenta');
        if (onSuccess) {
          onSuccess(patient);
        }
      },
    },
  );
