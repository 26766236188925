import { PatientDTO, Status, VisitDTO } from '~/dtos';
import { Button, Card, List, Row, Typography, DatePicker, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import React from 'react';
import dayjs from 'dayjs';
import { fullDateWithMonthFormat } from '~/constants';
import { useNurseObservations } from '~/queries/useVisit';
import { Link } from 'react-router-dom';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

export interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const NurseObservationsView = ({ visit, patient }: Props) => {
  const [dates, setDates] = React.useState<
    [moment.Moment | null, moment.Moment | null] | null
  >(null);
  const { data, isLoading } = useNurseObservations(visit.id);
  const history = useHistory();
  const formUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.NURSE_OBSERVATION_FORM,
    patient.id,
    visit.id,
  );

  const filteredData = React.useMemo(() => {
    if (!data) {
      return [];
    }

    if (!dates) {
      return data;
    }

    return data.filter((item) => {
      const createdAt = moment(item.createdAt);
      return createdAt.isBetween(dates[0], dates[1], 'day', '[]');
    });
  }, [data, dates]);

  return (
    <div style={{ padding: 16 }}>
      <Row justify='space-between' align='middle' style={{ width: '100%' }}>
        <Typography.Title level={2}>Obserwacje pielęgniarskie</Typography.Title>

        <Space>
          Filtruj po datach:
          <RangePicker
            value={dates}
            onChange={(dates) => setDates(dates ? [dates[0], dates[1]] : null)}
          />
          <Link to={formUrl}>
            <Button
              type='primary'
              style={{ width: 300 }}
              disabled={visit.status !== Status.ACTIVE}
            >
              Dodaj nowa obserwację
            </Button>
          </Link>
        </Space>
      </Row>
      <List
        dataSource={filteredData}
        itemLayout='vertical'
        loading={isLoading}
        size='small'
        renderItem={(item) => (
          <List.Item>
            <Card
              title={
                <b>
                  {item.addedBy.name} -{' '}
                  {`${fullDateWithMonthFormat(item.createdAt)} (${dayjs(
                    item.createdAt,
                  ).fromNow()})`}{' '}
                </b>
              }
              type='inner'
              size='small'
              extra={
                <Button
                  disabled={visit.status !== Status.ACTIVE}
                  onClick={() =>
                    history.push(formUrl, {
                      nurseObservation: item,
                    })
                  }
                >
                  <EditOutlined />
                </Button>
              }
            >
              {item.content}
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};
