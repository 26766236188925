import React from 'react';
import { DocumentDTO } from '~/dtos';
import { useDownloadExaminationDocument } from '~/queries/useDocument';
import { FilePdfOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface Props {
  document: DocumentDTO;
}

export const DownloadButton = ({ document }: Props) => {
  const { isLoading, mutate } = useDownloadExaminationDocument(
    document.id,
    (data) => {
      window.open(data.downloadUrl, '_blank');
    },
  );

  return (
    <Button
      size='small'
      type='primary'
      icon={<FilePdfOutlined />}
      loading={isLoading}
      onClick={() => mutate()}
    >
      {document.fileName}
    </Button>
  );
};
