import { Form, Modal } from 'antd';
import React, { useCallback } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PatientDTO } from '~/dtos';
import { UserSelect } from '~/components/UserSelect';
import { useUpdatePatient } from '~/queries/usePatients';

interface Props {
  visible: boolean;
  closeModal: () => void;
  patient: PatientDTO;
}

interface FormData {
  doctorId: string | null;
}

const schema = yup.object().shape({
  doctorId: yup.string().nullable(),
});

export const EditAssignedDoctorModal = ({
  visible,
  closeModal,
  patient,
}: Props) => {
  const { handleSubmit, control } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      doctorId: patient.doctorId,
    },
  });

  const { mutate, isLoading } = useUpdatePatient(patient.id, () => {
    closeModal();
  });

  const onSubmit = useCallback(
    (data: FormData) => {
      mutate({
        doctorId: data.doctorId,
      });
    },
    [mutate],
  );

  return (
    <Modal
      confirmLoading={isLoading}
      title='Wybierz terapuete prowadzącego'
      visible={visible}
      onOk={handleSubmit(onSubmit)}
      onCancel={() => closeModal()}
      okText='Aktualizuj'
      cancelText='Anuluj'
    >
      <Form layout='vertical'>
        <Form.Item>
          <Controller
            name='doctorId'
            control={control}
            render={({ field }) => (
              <UserSelect
                allowClear
                onClear={() => field.onChange(null)}
                onSelect={field.onChange}
                value={field.value}
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
