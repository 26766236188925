import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as rax from 'retry-axios';
import qs from 'query-string';

import { AuthService } from '~/services/Auth';
import { ErrorPageParams } from '~/components/GenericErrorPage';

import { loadAuthTokens } from '~/utils/auth0-tokens';

import { API_URL, ROUTES } from '~/constants';

export class RequestService {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
    });

    this.setRetry();
    this.setAuthenticationInterceptor();
    this.setReauthenticateInterceptor();
  }

  setRetry() {
    rax.attach(this.client);
  }

  setAuthenticationInterceptor() {
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      const tokens = loadAuthTokens();

      if (tokens.accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: 'Bearer ' + tokens.accessToken,
        };
      }

      return config;
    });
  }

  setReauthenticateInterceptor() {
    this.client.interceptors.response.use(undefined, async (error) => {
      if (error.response && error.response.status === 401) {
        return AuthService.renewToken()
          .then(() => this.client(error.config))
          .catch(() => {
            const params: ErrorPageParams = {
              error: 'Unauthorized',
              errorDescription: 'You have been logged out.',
              showHomeButton: true,
              showRetryButton: false,
            };

            const queryParams = qs.stringify({ ...params });
            const url = `${ROUTES.ERROR}?${queryParams}`;

            return AuthService.logout(url);
          });
      }

      throw error;
    });
  }
}

export default new RequestService();
