import React, { useMemo, useState } from 'react';
import {
  createPatientVisitRoute,
  fullDateWithMonthFormat,
  insertIdToRoute,
  ROUTES,
} from '~/constants';
import {
  Button,
  Divider,
  Space,
  PageHeader,
  Select,
  Alert,
  Row,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import {
  PatientDTO,
  Status,
  STATUS_TO_COLOR,
  STATUS_TO_HUMAN_STRING,
  VisitDTO,
} from '~/dtos';
import { useHistory, useLocation } from 'react-router';
import { findActiveVisit } from '~/selectors/patient';
import { CancelVisitModal } from './CancelVisitModal';

const {
  ROOT,
  EXAMINATIONS,
  ENTRY_OBSERVATIONS,
  EXIT,
  OBSERVATIONS,
  FILES,
  SPECIALIST_OBSERVATIONS,
  NURSE_OBSERVATIONS,
} = ROUTES.dashboard.patient.visit;

interface LinkElement {
  link: string;
  label: string;
}

interface Props {
  patient: PatientDTO;
  selectedVisit: VisitDTO;
}

export const PatientPageHeader = ({ selectedVisit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const fullName = `${patient.firstName} ${patient.lastName}`;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const links = useMemo((): LinkElement[] | null => {
    if (!selectedVisit || !patient) {
      return null;
    }

    const generateUrl = (base: string) =>
      createPatientVisitRoute(base, patient.id, selectedVisit.id);

    return [
      {
        label: 'Informacje ogólne',
        link: generateUrl(ROOT),
      },
      {
        label: 'Wizyty przyjęciowe',
        link: generateUrl(ENTRY_OBSERVATIONS),
      },
      {
        label: 'Obserwacje',
        link: generateUrl(OBSERVATIONS),
      },
      {
        label: 'Obserwacje specjalistyczne',
        link: generateUrl(SPECIALIST_OBSERVATIONS),
      },
      {
        label: 'Obserwacje pielęgniarskie',
        link: generateUrl(NURSE_OBSERVATIONS),
      },
      {
        label: 'Wykonane badania dodatkowe',
        link: generateUrl(EXAMINATIONS),
      },
      {
        label: 'Dokumenty',
        link: generateUrl(FILES),
      },
      { label: 'Wypis', link: generateUrl(EXIT) },
    ];
  }, [selectedVisit, patient]);

  const extras = useMemo(() => {
    const elements = [
      <Select<string>
        key='select'
        onSelect={(visitId: string) => {
          const url = createPatientVisitRoute(ROOT, patient.id, visitId);
          history.push(url);
        }}
        style={{ width: 360 }}
        placeholder='Wybierz wizytę'
        value={selectedVisit.id}
      >
        {patient.visits.map((el, idx) => {
          return (
            <Select.Option value={el.id} key={idx}>
              <Tag color={STATUS_TO_COLOR[el.status]}>
                {STATUS_TO_HUMAN_STRING[el.status]}
              </Tag>
              {startCase(el.department)} – Pobyt od{' '}
              {fullDateWithMonthFormat(el.startedAt)}
            </Select.Option>
          );
        })}
      </Select>,
    ];

    const activeVisit = findActiveVisit(patient);

    if (!activeVisit) {
      const url = insertIdToRoute(ROUTES.dashboard.NEW_VISIT, patient.id);

      elements.unshift(
        <Link to={url} key='new-button'>
          <Button>Nowy pobyt</Button>
        </Link>,
      );
    }

    if (selectedVisit.status !== Status.ACTIVE) {
      const text =
        selectedVisit.status === Status.CANCELLED ? 'anulowana' : 'zakończona';

      elements.unshift(
        <Alert
          style={{ padding: '4px 12px' }}
          key='alert'
          message={`Ta wizyta została ${text}. Nie można jej edytować.`}
          type='warning'
        />,
      );
    }

    return elements;
  }, [patient, history, selectedVisit]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <PageHeader
        title={fullName}
        subTitle={`#${patient.id}`}
        style={{ marginBottom: 8 }}
        extra={
          <Row>
            <Space wrap>
              <Button
                onClick={() => showModal()}
                hidden={!!selectedVisit.endedAt}
              >
                Anuluj pobyt
              </Button>
              {extras}
            </Space>
          </Row>
        }
        footer={
          links ? (
            <Space split={<Divider type='vertical' />} wrap>
              {links.map((l, idx) => (
                <Link to={l.link} key={idx}>
                  <Button
                    type={location.pathname === l.link ? 'text' : 'link'}
                    size='small'
                  >
                    {l.label}
                  </Button>
                </Link>
              ))}
            </Space>
          ) : null
        }
      />
      <CancelVisitModal
        visible={isModalVisible}
        onClose={closeModal}
        visit={selectedVisit}
      />
    </>
  );
};
