import React, { useState } from 'react';
import { Button, Card } from 'antd';
import { PatientDTO, VisitDTO } from '~/dtos';
import { EditOutlined } from '@ant-design/icons';
import { DoctorName } from '~/components/DoctorName';
import { EditAssignedDoctorModal } from '~/components/EditAssignedDoctorModal';

interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const DoctorDetails = ({ patient }: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <Card
      title='Terapueta prowadzący'
      extra={
        <Button size='small' onClick={() => setVisible(true)}>
          <EditOutlined />
        </Button>
      }
      type='inner'
      size='small'
    >
      <DoctorName doctor={patient.doctor} />
      <EditAssignedDoctorModal
        patient={patient}
        visible={visible}
        closeModal={() => setVisible(false)}
      />
    </Card>
  );
};
