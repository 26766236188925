import React from 'react';
import {
  EpicrisisType,
  PatientDTO,
  VisitDTO,
  CreateEpicrisisDTO,
  Status,
} from '~/dtos';
import { useVisitEpicrisises } from '~/queries/useVisit';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Empty,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from 'antd';

import { EpicrisesList } from './EpicrisesList';
import {
  createAddEpicrisisRoute,
  createPatientVisitRoute,
  ROUTES,
} from '~/constants';

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

const ALL_TYPES = Object.values(EpicrisisType);
const options = ALL_TYPES.map((e) => ({ label: e, value: e }));

export const Epicrisies = ({ visit, patient }: Props) => {
  const history = useHistory();
  const { data, isLoading } = useVisitEpicrisises(visit.id);
  const addedEpicrises = (data || []).map((e) => e.type);
  const missingEpicrises = ALL_TYPES.filter((e) => !addedEpicrises.includes(e));
  const missingRequiredEpicrises = [
    EpicrisisType.PSYCH,
    EpicrisisType.PSYCHTER,
  ].filter((e) => !addedEpicrises.includes(e));
  const generateExitDocumentUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.GENERATE_EXIT,
    patient.id,
    visit.id,
  );

  const editUrl = (epicrisise: CreateEpicrisisDTO) => {
    history.push(
      createAddEpicrisisRoute(patient.id, visit.id, epicrisise.type),
      {
        epicrisise,
      },
    );
  };

  return (
    <div>
      <Row justify='space-between'>
        <Typography.Title level={3}>Epikryzy:</Typography.Title>
        {missingEpicrises.length > 0 && visit.status === Status.ACTIVE && (
          <Select<EpicrisisType>
            onSelect={(el: EpicrisisType) => {
              const url = createAddEpicrisisRoute(patient.id, visit.id, el);
              history.push(url);
            }}
            placeholder='Dodaj epikryze'
            style={{ width: 300 }}
          >
            {missingEpicrises.map((el, idx) => (
              <Select.Option value={el} key={idx}>
                {el}
              </Select.Option>
            ))}
          </Select>
        )}
      </Row>

      <Skeleton loading={isLoading} active>
        {data && data.length ? (
          <EpicrisesList epicrisises={data} editUrl={editUrl} visit={visit} />
        ) : (
          <Empty description='Brak dodanych epikryz' />
        )}
      </Skeleton>

      <Space
        direction='vertical'
        align='center'
        style={{ width: '100%', marginTop: 40 }}
      >
        <Checkbox.Group options={options} value={addedEpicrises} disabled />

        {missingRequiredEpicrises.length > 0 ? (
          <Typography.Paragraph>
            Aby móc wygenerować wypis wymaganym jest, aby dodać epikryzy:
            psychiatryczna i psychoterapeutyczna.
          </Typography.Paragraph>
        ) : null}

        {missingRequiredEpicrises.length === 0 ? (
          <Typography.Paragraph>
            Wszystkie wymagane epikryzy zostały dodane. Możesz teraz wygenerować
            wypis.
          </Typography.Paragraph>
        ) : null}

        <Button
          type='primary'
          disabled={
            missingRequiredEpicrises.length > 0 ||
            visit.status !== Status.ACTIVE
          }
          onClick={() => history.push(generateExitDocumentUrl)}
        >
          Rozpocznij generacje poglądowego wypisu
        </Button>
      </Space>
    </div>
  );
};
