import qs from 'query-string';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import { ROUTES } from '~/constants';
import { ErrorPageParams } from '~/components/GenericErrorPage';
import { useStoreState } from '~/global-state/hooks';

const urlParams: ErrorPageParams = {
  error: 'Wymagana autoryzacja',
  errorDescription:
    'Aby wejść na tą stronę potrzebujesz być zalogowany/zalogowana.',
  showHomeButton: true,
};

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    const parsedParams = qs.stringify({ ...urlParams });
    const url = `${ROUTES.ERROR}?${parsedParams}`;

    return <Redirect to={url} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
