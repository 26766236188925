import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { hot } from 'react-hot-loader/root';
import { useStoreRehydrated } from 'easy-peasy';
import { QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';

import { queryClient } from '~/constants';

import AppViews from './views';
import { useStoreActions } from '~/global-state/hooks';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { history } from '~/utils/history';
import { isAuthenticatedInStorage } from './utils/auth0-tokens';

const App: React.FC = () => {
  const [initialized, setInitialized] = useState(false);
  const rehydrated = useStoreRehydrated();

  const setAsAuthenticated = useStoreActions(
    (actions) => actions.auth.setAsAuthenticated,
  );

  useEffect(() => {
    if (rehydrated) {
      if (isAuthenticatedInStorage()) {
        setAsAuthenticated();
      }
      setInitialized(true);
    }
  }, [rehydrated]); // eslint-disable-line

  if (!initialized) {
    // TODO Add something pretty here
    return null;
  }

  return (
    <ConfigProvider locale={plPL}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <LastLocationProvider>
            <ErrorBoundary>
              <AppViews />
            </ErrorBoundary>
          </LastLocationProvider>
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default hot(App);
