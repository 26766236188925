import { Button, Space } from 'antd';
import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

interface Props {
  title: string;
}

export const GoBackButton = ({ title }: Props) => {
  const history = useHistory();
  return (
    <Space>
      <Button onClick={() => history.goBack()}>
        <ArrowLeftOutlined />
      </Button>
      {title}
    </Space>
  );
};
