import React, { useMemo, useState } from 'react';
import { DrugDTO, Status, VisitDTO } from '~/dtos';
import { useVisitDrugs } from '~/queries/useVisit';
import { ColumnsType } from 'antd/es/table';
import { Button, Row, Table, Typography } from 'antd';

import { DrugActions } from './Actions';
import { DrugsFormModal } from './drugs-form-modal';

const generateColumns = (
  visit: VisitDTO,
  setSelectedItem: (res: DrugDTO | null) => void,
  allowEditing: boolean,
): ColumnsType<DrugDTO> => {
  const columns: ColumnsType<DrugDTO> = [
    {
      title: 'Nazwa leku',
      dataIndex: 'name',
    },
    {
      title: 'Rano',
      dataIndex: 'morning',
      align: 'center',
    },
    {
      title: 'Południe',
      dataIndex: 'noon',
      align: 'center',
    },
    {
      title: 'Wieczór',
      dataIndex: 'evening',
      align: 'center',
    },
    {
      title: 'Noc',
      dataIndex: 'night',
      align: 'center',
    },
  ];

  if (allowEditing) {
    columns.push({
      title: 'Akcje',
      align: 'center',
      key: '-',
      render: (_, drug) => (
        <DrugActions drug={drug} visit={visit} onSelectItem={setSelectedItem} />
      ),
    });
  }

  return columns;
};

interface Props {
  visit: VisitDTO;
}

export const Drugs = ({ visit }: Props) => {
  const { data, isLoading } = useVisitDrugs(visit.id);
  const [selectedItem, setSelectedItem] = useState<DrugDTO | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = useMemo(
    () =>
      generateColumns(
        visit,
        (item) => {
          setSelectedItem(item);
          setIsModalVisible(true);
        },
        !visit.endedAt,
      ),
    [visit],
  );

  return (
    <div>
      <Row justify='space-between' style={{ width: '100%' }}>
        <Typography.Title level={3}>Farmakoterapia:</Typography.Title>
        <Button
          type='primary'
          onClick={() => setIsModalVisible(true)}
          style={{ width: 200 }}
          disabled={visit.status !== Status.ACTIVE}
        >
          Dodaj lek
        </Button>{' '}
      </Row>
      <Table<DrugDTO>
        rowKey='id'
        size='small'
        bordered
        pagination={false}
        columns={columns}
        dataSource={data}
        loading={isLoading}
      />
      {(selectedItem || isModalVisible) && (
        <DrugsFormModal
          isModalVisible={isModalVisible}
          item={selectedItem}
          visitId={visit.id}
          onClose={() => {
            setSelectedItem(null);
            setIsModalVisible(false);
          }}
        />
      )}
    </div>
  );
};
