import React, { ErrorInfo } from 'react';
import { captureException, showReportDialog, withScope } from '@sentry/browser';

import { GenericErrorPage } from '~/components/GenericErrorPage';
import { PROD_ENV, PROD_ERROR_MESSAGE } from '~/constants';

interface State {
  error: Error | null;
}

export class ErrorBoundary extends React.Component<unknown, State> {
  state = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });

    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      captureException(error);
    });
  }

  handleReportErrorClick = () => {
    showReportDialog();
  };

  render() {
    if (this.state.error) {
      // Type checking disabled here, because TypeScript doesn't
      // understand using conditional statements with React state fields.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorName = PROD_ENV ? PROD_ERROR_MESSAGE : this.state.error.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const errorDescription = PROD_ENV ? undefined : this.state.error.message;

      /**
       * Since sending issues by sentry is activated only when PROD_ENV flag is true,
       * the reporting issues works only then. Therefore, there is no point of showing
       * the report button (which is shown when handleErrorClick function isa passed
       * to generic error page) since it wouldn't work anyway.
       */
      return (
        <GenericErrorPage
          handleReportErrorClick={
            PROD_ENV ? this.handleReportErrorClick : undefined
          }
          error={errorName}
          errorDescription={errorDescription}
          showGoBackButton
          reloadOnGoBack
          showRetryButton
        />
      );
    }

    return this.props.children;
  }
}
