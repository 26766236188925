import React from 'react';
import { PatientDTO, VisitDTO } from '~/dtos';
import { Route, Switch } from 'react-router';

import { PatientDetailsView } from './views/patient-details';
import { ExaminationsView } from './views/examinations';
import { EntryObservationsView } from './views/entry-observations';
import { ExitView } from './views/exit';
import { ExaminationForm } from './views/examination-form';
import { ObservationsView } from './views/observations';
import { EpicrisiseForm } from './views/epicrisis-form';
import { EntryObservationForm } from './views/entry-observation-form';
import { ObservationForm } from './views/observation-form';
import { ExitGenerateView } from '~/views/dashboard/patient/views/exit-generate';
import { SpecialistObservationsView } from './views/specialist-observations';
import { SpecialistObservationForm } from './views/specialist-observations-form';
import { VisitFilesView } from './views/files';
import { ROUTES } from '~/constants';
import { NurseObservationsView } from '~/views/dashboard/patient/views/nurse-observations';
import { NurseObservationForm } from '~/views/dashboard/patient/views/nurse-observations-form';

const {
  ROOT,
  EXAMINATIONS,
  ENTRY_OBSERVATIONS,
  EXIT,
  EPICRISIS_FORM,
  ENTRY_OBSERVATION_FORM,
  OBSERVATIONS,
  EXAMINATIONS_FORM,
  GENERATE_EXIT,
  SPECIALIST_OBSERVATIONS,
  PSYCHO_OBSERVATION_FORM,
  OBSERVATION_FORM,
  NURSE_OBSERVATION_FORM,
  NURSE_OBSERVATIONS,
  FILES,
} = ROUTES.dashboard.patient.visit;

interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const PatientRouter = ({ patient, visit }: Props) => {
  return (
    <Switch>
      <Route path={ROOT} exact>
        <PatientDetailsView patient={patient} visit={visit} />
      </Route>
      <Route path={OBSERVATIONS} exact>
        <ObservationsView patient={patient} visit={visit} />
      </Route>
      <Route path={SPECIALIST_OBSERVATIONS} exact>
        <SpecialistObservationsView visit={visit} patient={patient} />
      </Route>
      <Route path={PSYCHO_OBSERVATION_FORM} exact>
        <SpecialistObservationForm patient={patient} visit={visit} />
      </Route>
      <Route path={NURSE_OBSERVATIONS} exact>
        <NurseObservationsView visit={visit} patient={patient} />
      </Route>
      <Route path={NURSE_OBSERVATION_FORM} exact>
        <NurseObservationForm patient={patient} visit={visit} />
      </Route>
      <Route path={EXAMINATIONS_FORM} exact>
        <ExaminationForm patient={patient} visit={visit} />
      </Route>
      <Route path={EPICRISIS_FORM} exact>
        <EpicrisiseForm patient={patient} visit={visit} />
      </Route>
      <Route path={GENERATE_EXIT} exact>
        <ExitGenerateView patient={patient} visit={visit} />
      </Route>
      <Route path={OBSERVATION_FORM} exact>
        <ObservationForm patient={patient} visit={visit} />
      </Route>
      <Route path={ENTRY_OBSERVATION_FORM} exact>
        <EntryObservationForm patient={patient} visit={visit} />
      </Route>
      <Route path={EXIT} exact>
        <ExitView patient={patient} visit={visit} />
      </Route>
      <Route path={EXAMINATIONS} exact>
        <ExaminationsView patient={patient} visit={visit} />
      </Route>
      <Route path={ENTRY_OBSERVATIONS} exact>
        <EntryObservationsView patient={patient} visit={visit} />
      </Route>
      <Route path={FILES} exact>
        <VisitFilesView patient={patient} visit={visit} />
      </Route>
    </Switch>
  );
};
