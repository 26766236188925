import { useLocation } from 'react-router-dom';

import { locationParse, parseErrorWithCode } from './helpers';

export const useErrorFromUrlParams = () => {
  const location = useLocation();
  const {
    error,
    code,
    errorDescription,
    showRetryButton,
    showHomeButton,
    showGoBackButton,
    pageTitle,
  } = locationParse(location.search);

  return {
    error: parseErrorWithCode({ error, code }),
    errorDescription: errorDescription,
    showRetryButton,
    showHomeButton,
    showGoBackButton,
    pageTitle,
  };
};
