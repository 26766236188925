import React from 'react';
import { Divider, Typography } from 'antd';

import { Drugs } from './Drugs';
import { Epicrisies } from './Epicrisises';
import { VisitForm } from './Form';
import { ExitDetails } from './ExitDetails';

import { PatientDTO, VisitDTO } from '~/dtos';

export interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const ExitView = ({ patient, visit }: Props) => {
  return (
    <div style={{ padding: 16 }}>
      <Typography.Title level={2}>Wypis</Typography.Title>
      {visit.excerpt && <ExitDetails document={visit.excerpt} visit={visit} />}
      <VisitForm visit={visit} patient={patient} />
      <Divider />
      <Drugs visit={visit} />
      <Divider />
      <Epicrisies visit={visit} patient={patient} />
    </div>
  );
};
