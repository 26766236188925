import { ObservationDTO, PatientDTO, Status, VisitDTO } from '~/dtos';
import { Button, Row, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { EditOutlined } from '@ant-design/icons';

import { useVisitObservations } from '~/queries/useVisit';
import {
  createPatientVisitRoute,
  fullDateWithMonthFormat,
  ROUTES,
} from '~/constants';
import { Link, useHistory } from 'react-router-dom';

const generateColumns = (
  editFormUrl: (res: any) => void,
  allowEditing: boolean,
): ColumnsType<ObservationDTO> => {
  const columns: ColumnsType<ObservationDTO> = [
    {
      title: 'Tętno (na minutę)',
      dataIndex: 'pulse',
      key: 'pulse',
      align: 'center',
      width: 160,
    },
    {
      title: 'Temperatura °C',
      dataIndex: 'temperature',
      key: 'temperature',
      align: 'center',
      width: 180,
      render: (_, observation) => `${observation.temperature} °C`,
    },
    {
      title: 'Waga (kg)',
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      width: 100,
      render: (_, observation) =>
        observation.weight ? `${observation.weight} kg` : null,
    },
    {
      title: 'Wzrost (cm)',
      dataIndex: 'height',
      key: 'height',
      align: 'center',
      width: 120,
      render: (_, observation) =>
        observation.height ? `${observation.height} cm` : null,
    },
    {
      title: 'Ciśnienie',
      dataIndex: 'pressure',
      key: 'pressure',
      align: 'center',
      width: 140,
    },
    {
      title: 'Dodatkowe informacje',
      dataIndex: 'additionalInformation',
      key: 'additionalInformation',
    },
    {
      title: 'Dodano',
      dataIndex: 'addedBy',
      key: 'addedBy',
      align: 'center',
      width: 400,
      render: (_, observation) =>
        `${observation.addedBy.name} – ${fullDateWithMonthFormat(
          observation.createdAt,
        )}`,
    },
  ];

  if (allowEditing) {
    columns.push({
      title: 'Akcje',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 100,
      render: (_, observation) => (
        <Button onClick={() => editFormUrl(observation)}>
          <EditOutlined />
        </Button>
      ),
    });
  }

  return columns;
};

export interface Props {
  patient: PatientDTO;
  visit: VisitDTO;
}

export const ObservationsView = ({ visit, patient }: Props) => {
  const { data, isLoading } = useVisitObservations(visit.id);
  const history = useHistory();
  const formUrl = createPatientVisitRoute(
    ROUTES.dashboard.patient.visit.OBSERVATION_FORM,
    patient.id,
    visit.id,
  );

  const editFormUrl = (observation: ObservationDTO) =>
    history.push(formUrl, {
      observation: observation,
    });

  return (
    <div style={{ padding: 16 }}>
      <Row justify='space-between' style={{ width: '100%' }}>
        <Typography.Title level={2}>Obserwacje</Typography.Title>
        <Link to={formUrl}>
          <Button
            type='primary'
            style={{ width: 300 }}
            disabled={visit.status !== Status.ACTIVE}
          >
            Dodaj nowa obserwacje
          </Button>
        </Link>
      </Row>
      <Table<ObservationDTO>
        rowKey='id'
        columns={generateColumns(editFormUrl, !visit.endedAt)}
        loading={isLoading}
        dataSource={data}
        bordered
      />
    </div>
  );
};
