import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextArea from 'antd/es/input/TextArea';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, DatePicker, Form, Input } from 'antd';
import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';
import { createPatientVisitRoute, ROUTES } from '~/constants';
import {
  useCreateObservation,
  useUpdateObservations,
} from '~/queries/useVisit';
import {
  CreateObservationDTO,
  ObservationDTO,
  PatientDTO,
  VisitDTO,
} from '~/dtos';
import { InputProps } from 'antd/lib/input/Input';
import { GoBackButton } from '~/components/GoBackButton';
import moment from 'moment';

const schema = yup.object().shape({
  weight: yup
    .number()
    .positive('Ta wartość musi być większa od zera.')
    .optional()
    .nullable()
    .transform((v: number) => (isNaN(v) ? null : v)),
  additionalInformation: yup.string().optional(),
  createdAt: yup.string().required('To pole jest wymagane'),
  pulse: yup.number().positive().required('To pole jest wymagane.'),
  pressure: yup.string().required('To pole jest wymagane.'),
  temperature: yup.number().required('To pole jest wymagane.'),
  height: yup
    .number()
    .positive('Ta wartość musi być większa od zera.')
    .optional()
    .nullable()
    .transform((v: number) => (isNaN(v) ? null : v)),
});

interface Props {
  visit: VisitDTO;
  patient: PatientDTO;
}

export const ObservationForm = ({ visit, patient }: Props) => {
  const history = useHistory();
  const location = useLocation<{ observation?: ObservationDTO }>();
  const isEditing = Boolean(location.state?.observation);

  const { handleSubmit, control, formState, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (location.state?.observation) {
      const { observation } = location.state;
      setValue('weight', observation.weight);
      setValue('additionalInformation', observation.additionalInformation);
      setValue('pulse', observation.pulse);
      setValue('pressure', observation.pressure);
      setValue('temperature', observation.temperature);
      setValue('height', observation.height);
      setValue('createdAt', moment(observation.createdAt));
    } else {
      setValue('createdAt', moment());
    }
  }, [location.state, setValue]);

  const { mutate: createObservation, isLoading } = useCreateObservation(
    visit.id,
    () => {
      history.push(
        createPatientVisitRoute(
          ROUTES.dashboard.patient.visit.OBSERVATIONS,
          patient.id,
          visit.id,
        ),
      );
    },
  );

  const { mutate: updateObservation } = useUpdateObservations(
    visit.id,
    location.state?.observation?.id || '',
    () => {
      history.goBack();
    },
  );

  const onSubmit = useCallback(
    (data) => {
      if (isEditing) {
        updateObservation(data);
      } else {
        createObservation(data);
      }
    },
    [createObservation, updateObservation, isEditing],
  );

  const renderInput = useCallback(
    (
      label: string,
      required: boolean,
      name: keyof CreateObservationDTO,
      props?: InputProps,
    ) => {
      const errors = formState.errors[name];
      return (
        <Form.Item
          label={label}
          required={required}
          validateStatus={formState.errors[name] ? 'error' : 'validating'}
          help={errors ? errors.message : undefined}
        >
          <Controller
            name={name}
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Input {...field} value={field.value || ''} {...props} />
            )}
          />
        </Form.Item>
      );
    },
    [formState, control],
  );

  const {
    errors: { additionalInformation },
  } = formState;

  return (
    <Card
      title={
        <GoBackButton
          title={`Formularz ${
            isEditing ? 'edytowania' : 'dodawanie'
          } obserwacji`}
        />
      }
      style={{ marginTop: 16 }}
    >
      <Form layout='vertical' onSubmitCapture={handleSubmit(onSubmit)}>
        <Form.Item
          label='Data observacji'
          required
          style={{ width: '100%' }}
          validateStatus={formState.errors.createdAt ? 'error' : 'validating'}
          help={
            formState.errors.createdAt
              ? formState.errors.createdAt.message
              : undefined
          }
        >
          <Controller
            name='createdAt'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <DatePicker
                allowClear={false}
                placeholder='Wybierz date'
                onChange={field.onChange}
                value={field.value}
                style={{ width: '100%' }}
              />
            )}
          />
        </Form.Item>

        {renderInput('Tętno (na minutę)', true, 'pulse', {
          type: 'number',
          min: 0,
          placeholder: '82',
        })}
        {renderInput('Ciśnienie', true, 'pressure', {
          placeholder: 'Wpisz w formacie np. 80/120',
        })}
        {renderInput('Temperatura °C', true, 'temperature', {
          type: 'number',
          min: 0,
          placeholder: '36.6',
          step: 0.1,
        })}
        {renderInput('Masa ciała w kg', false, 'weight', {
          type: 'number',
          min: 0,
          placeholder: '60',
        })}
        {renderInput('Wzrost w cm', false, 'height', {
          type: 'number',
          min: 0,
          placeholder: '166',
        })}

        <Form.Item
          label='Informacje dodatkowe'
          validateStatus={additionalInformation ? 'error' : 'validating'}
          help={
            additionalInformation ? additionalInformation.message : undefined
          }
        >
          <Controller
            name='additionalInformation'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextArea
                {...field}
                autoSize
                value={field.value || ''}
                placeholder='Opcjonalne informacje dodatkowe'
                style={{ width: '100%' }}
                onChange={field.onChange}
              />
            )}
          />
        </Form.Item>

        <Button
          block
          type='primary'
          onClick={handleSubmit(onSubmit)}
          loading={isLoading}
        >
          {isEditing ? 'Edytuj obserwacje' : 'Dodaj obserwacje'}
        </Button>
      </Form>
    </Card>
  );
};
